<ul  *ngIf="userACL" cListGroup>
  <li cListGroupItem>
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'user_status' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{targetObject.accountStatus ?? '' | translate}}</strong>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem>
    <c-row>
      <c-col sm="12" md="6"><strong>{{'employee' | translate}}:</strong></c-col>
      <c-col sm="12" md="6"><strong>{{(userACL.employee? 'yes' : 'non') | translate}}</strong></c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="!userACL.employee && (isAdmin)">
    <c-row>
      <c-col sm="12" md="6">
        {{'this_user_isnt_beneficiairy'|translate}}
      </c-col>
      <c-col sm="12" md="6">
        <button class="btn btn-primary" type="button" (click)="makeEmployee()">
          {{'make_employee' | translate}}
        </button>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem>
    <c-row>
      <c-col sm="12" md="6"><strong>{{'admin' | translate}}:</strong></c-col>
      <c-col sm="12" md="6">{{(userACL.admin? 'yes' : 'non') | translate}}</c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="!isSelf && (isAdmin || isSuperAdmin)">
    <c-row>
      <c-col sm="12" md="6">
        @if(targetObject.appRoles.admin) {
          <span>{{'revoke_admin_explanation'|translate}}</span>
        } @else {
          <span>
            {{'make_admin_explanation'|translate}}
          </span>
        }
      </c-col>
      <c-col sm="12" md="6">
        <button class="btn btn-primary" type="button" (click)="makeAdmin()">
          {{(userACL.admin ? 'revoke_admin': 'make_admin') | translate}}
        </button>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="!isSelf && (isAccountManager || isSuperAdmin)">
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'accountmanager' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{(userACL.accountmanager? 'yes' : 'non') | translate}}</strong>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="!isSelf && (isAccountManager || isSuperAdmin)">
    <c-row>
      <c-col sm="12" md="6"></c-col>
      <c-col sm="12" md="6">
        <button class="btn btn-primary" type="button" (click)="makeAccountManager()">
          {{(userACL.accountmanager ? 'revoke_accountmanager': 'make_accountmanager') | translate}}
        </button>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem>
    <c-row>
      <c-col sm="12" md="6">
        <strong *ngIf="!suspended">
          {{'suspend_user_explanation'|translate}}
        </strong>
        <strong *ngIf="suspended">
          {{'user_is_currently_suspended'|translate}}
        </strong>
      </c-col>
      <c-col sm="12" md="6">
        <button class="btn btn-danger" type="button" (click)="suspendUser()">
          <span *ngIf="!suspended">{{'suspend_user' | translate}}</span>
          <span *ngIf="suspended">{{'unsuspend_user' | translate}}</span>
        </button>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem>
    <c-row>
      <c-col sm="12" md="6">
        <strong>
          {{'delete_user_explanation'|translate}}
        </strong>
      </c-col>
      <c-col sm="12" md="6">
        <button class="btn btn-danger" type="button" (click)="deleteUser()">
          {{'delete_user' | translate}}
        </button>
      </c-col>
    </c-row>
  </li>
</ul>
<p *ngIf="!userACL && targetObject">
  {{'isInvitedButNotSubscribed'|translate}}<br>
  {{'below_access_level_granted_when_invited'|translate}}
</p>
<ul  *ngIf="!userACL && targetObject?.appRoles" cListGroup>
  <li cListGroupItem>
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'employee' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{(targetObject.appRoles.employee ? 'yes': 'no') | translate}}</strong>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="isAdmin || isSuperAdmin">
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'admin' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{(targetObject.appRoles.admin ? 'yes': 'no') | translate}}</strong>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="isAccountManager || isSuperAdmin">
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'accountmanager' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{(targetObject.appRoles.accountmanager ? 'yes': 'no') | translate}}</strong>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="isSuperAdmin">
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'agent' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{(targetObject.appRoles.agent ? 'yes': 'no') | translate}}</strong>
      </c-col>
    </c-row>
  </li>
  <li cListGroupItem *ngIf="isSuperAdmin">
    <c-row>
      <c-col sm="12" md="6">
        <strong>{{'superadmin' | translate}}:</strong>
      </c-col>
      <c-col sm="12" md="6">
        <strong>{{(targetObject.appRoles.superadmin ? 'yes': 'no') | translate}}</strong>
      </c-col>
    </c-row>
  </li>
</ul>