<h5>
  {{'please_read_and_sign'|translate}}
</h5>
<rm-attestation
      *ngIf="!loading && html && beforSignatureText"
      [attestationType]="'IKV'"
      [htmlText]="html"
      [beforSignatureText]="beforSignatureText"
      [pdfText]="pdfText"
      [attestationObject]="'ikv_refund_request'"
      [isIKV]="true"
      (urlReadyEmitter)="saveAttestation($event)"
    >
</rm-attestation>
<c-progress variant="striped" *ngIf="loading" [value]="100" color="primary" animated class="mb-3"/>
