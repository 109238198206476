import { Component, Input, OnInit } from '@angular/core';
import { Kyb, Treezor } from '@roadmate/roadmate-common';
import { FireStoreService, RMToasterService } from '../../../../services';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { CardModule, ColComponent, ProgressComponent, RowComponent } from '@coreui/angular';
import { ReactiveFormsComponent } from '../../../reactive-forms/reactive-forms.component';
import { FilterArrayPipe, TruncPipe } from '../../../pipes';

@Component({
  selector: 'rm-view-kyb-beneficiaries',
  templateUrl: './view-kyb-beneficiaries.component.html',
  standalone: true,
  imports: [
    NgIf, NgFor, TranslateModule, AccordionModule, ReactiveFormsComponent,
    RowComponent, ColComponent, FilterArrayPipe, CardModule, TruncPipe, ProgressComponent
  ]
})
export class ViewKybBeneficiariesComponent implements OnInit {
  @Input({
    required: true
  })
  kyb: Kyb;
  treezorDef: any;
  public treezorUsers: Treezor.User.EmployeeProfile[];
  public treezorUploadedDocuments: Treezor.Document.Definition[];
  constructor(
    private fs: FireStoreService,
    private toast: RMToasterService
  ) { }

  ngOnInit(): void {
    this.treezorDef = this.fs.getObjectDefinitionByRef('treezor-user');
    this.getTreezorUser();
  }

  private async getTreezorUser() {
    const { ref, agentRef } = this.kyb;
    if (!ref || !agentRef) {
      this.toast.showGenericError('Il manque la ref ou l\'agent ref');
      return;
    }
    this.treezorUsers = await this.fs.getkybUsers(agentRef, ref);
    this.treezorUploadedDocuments = await this.fs.getUploadedKybDocuments(agentRef, ref);
  }
}
