<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container *ngIf="ready">
  <c-container [fluid]="true" class="border-bottom px-4">
    <button
      [cSidebarToggle]="sidebarId"
      cHeaderToggler
      class="btn"
      toggle="visible"
      style="margin-inline-start: -14px;"
      aria-label="Toggle sidebar navigation"
      class="mobile-only"
      (change)="onSidebarToggle($event)"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>

   <!--  <c-header-nav class="d-none d-md-flex">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/users" routerLinkActive="active">Users</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/settings" routerLinkActive="active">Settings</a>
      </c-nav-item> 
    </c-header-nav>
    -->
    <c-header-brand *ngIf="headerTitle" [routerLink] class="ms-auto desktop-only">
      <h2>
        {{headerTitle}}
      </h2>
    </c-header-brand>

    <c-header-nav class="d-none d-md-flex ms-auto ">
      <!-- <a cNavLink>
        <svg cIcon class="my-1" name="cilBell" size="lg"></svg>
      </a>
      <a cNavLink>
        <svg cIcon class="my-1" name="cilList" size="lg"></svg>
      </a> -->
      <a cNavLink [routerLink]="supportUrl">
        <!-- <svg cIcon class="my-1" name="cilEnvelopeOpen" size="lg"></svg> -->
        <!-- <svg cIcon class="my-1" name="cilChatBubble" size="lg"></svg> -->
         <svg [cIcon]="myIcons.cilChatBubble" size="lg" title=""></svg>
      </a>
    </c-header-nav>

    <c-header-nav class="ms-auto ms-md-0">
      <div class="nav-item py-1">
        <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
      </div>
      <ng-container *ngTemplateOutlet="themeDropdown" />
      <div class="nav-item py-1">
        <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
      </div>
    </c-header-nav>

    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown" />
    </c-header-nav>

  </c-container>
  <c-container [fluid]="true" class="px-4">
    <c-breadcrumb-router />
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item">
    <button [caret]="false" cDropdownToggle class="py-0 pe-0" aria-label="Open user menu">
      <div class="profile-image">
        {{initials}}
      </div>
    </button>
    <ul cDropdownMenu class="pt-0 w-auto" *ngIf="navLinks.length">
      <li *ngFor="let item of navLinks">
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top" *ngIf="item.title">
          {{item.name}}
        </h6>
        <a cDropdownItem [routerLink]="item.url" routerLinkActive="shortcutlinkactive"  *ngIf="item.url">
          <!-- <svg cIcon class="me-2" name="cilBell"></svg> -->
          <!-- <svg cIcon class="me-2" [attr.name]="item.iconComponent.name"></svg> -->
          <svg [cIcon]="item.iconComponent.content" class="me-2"  [attr.title]="item.name"></svg>
          {{item.name}}
          <!-- <c-badge class="ms-2 float-end" color="info"> 42</c-badge> -->
        </a>
        <div cDropdownMenu *ngIf="item.children?.length">
          {{item.name}}
          <svg [cIcon]="item.iconComponent.content" class="me-2"  [attr.title]="item.name"></svg>
        </div>
        <a cDropdownItem *ngIf="item.variant" class="clickable" (click)="toggleLoginAs(item.variant)">
          <svg [cIcon]="item.iconComponent.content" class="me-2"  [attr.title]="item.name"></svg>
          {{item.name}}
        </a>
        <div cDropdownDivider *ngIf="item.divider"></div>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle aria-label="Open theme picker">
      <svg cIcon [name]="icons()" size="lg"></svg>
    </button>
    <div cDropdownMenu>
      @for (mode of colorModes; track mode.name) {
        <button
          (click)="changeTheme(mode.name)"
          [active]="colorMode()===mode.name"
          [routerLink]="[]"
          cDropdownItem
          class="d-flex align-items-center"
        >
          <svg cIcon class="me-2" [name]="mode.icon" size="lg"></svg>
          {{ mode.text }}
        </button>
      }
    </div>
  </c-dropdown>
</ng-template>
