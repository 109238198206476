<h5>{{'confirm_delete_users_subtitle' | translate}}
  <span *ngIf="schedule">{{'scheduled_for' | translate}} {{startDate | rm_display_month_day}}</span>
  <span *ngIf="!schedule">{{'immediately' | translate}}</span>
  ?
</h5>
<table class="detached" cTable>
  <thead>
    <tr>
      <td scope="col">#</td>
      <td scope="col">{{'email' | translate}}</td>
      <td scope="col" style="text-align: center;">{{'validity' | translate}}</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let el of emailList; let i = index">
      <th  scope="row">{{i+1}}</th>
      <td>{{el.data}}</td>
      <td style="text-align: center;">
        <svg *ngIf="el.isSelected" [customClasses]="'green icon-height'" [cIcon]="icons.cilCheck" size="custom" title="Valide"></svg>
        <svg *ngIf="!el.isSelected" [customClasses]="'red icon-height'" [cIcon]="icons.cilXCircle" size="custom" title="Not Valide"></svg>
      </td>
    </tr>
  </tbody>
</table>

<c-row class="detached">
  <c-col sm="12" class="detached push-down" *ngIf="notifyUsers">
    {{'email_will_be_sent_to_scheduled_users' | translate}}
  </c-col>
  <c-col sm="12">
    <button class="btn btn-primary" type="button" (click)="confirm()">

      {{'confirm' | translate}}
    </button>
  </c-col>
</c-row>

