import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'rm_email_to_name', standalone: true})
export class EmailToNamePipe implements PipeTransform {
  transform(email: string): string {
    if (!email) {
      return '';
    }
    const emailParts = email.split('@');
    if (emailParts.length !== 2) {
      return '';
    }
    const nameParts = emailParts[0].split('.');
    if (nameParts.length < 2) {
      return '';
    }
    return nameParts.map((namePart) => namePart.charAt(0).toUpperCase() + namePart.slice(1)).join(' ');
  }
}