import { Component, Input, OnInit } from '@angular/core';
import { RMToasterService } from '../../services/toaster.service';
import { modes } from '../../static';
import { ButtonDirective, ToastBodyComponent, ToastComponent, ToasterComponent, ToastHeaderComponent } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'rm-toaster',
  templateUrl: './rm-toaster.component.html',
  styleUrls: ['./rm-toaster.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgFor, ToasterComponent, ToastComponent, ToastHeaderComponent, 
    // ToastSampleIconComponent, 
    ToastBodyComponent, 
    // ProgressBarDirective, ProgressComponent, ProgressBarComponent, 
    ButtonDirective
  ]
})
export class RMToasterComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() mode: modes;
  public allModes = modes;
  public isVisible = false;
  public toasts: {
    title: string;
    message: string;
    color: modes;
    visibile: boolean;
    createdAt: number;
    id: string;
  }[] = [];
  constructor(private toast: RMToasterService) {
    this.toast.toaster.subscribe(
      newToast => {
        // this.message = newToast.message ?? '';
        // this.title = newToast.title ?? '';
        // this.isVisible = !!newToast.isVisible;
        // this.mode = newToast.mode ?? modes.primary;
        this.toasts = [
          ...this.toasts,
          {
            title: newToast.title ?? '',
            message: newToast.message ?? '',
            color: newToast.mode ?? modes.primary,
            visibile: !!newToast.isVisible,
            createdAt: Date.now(),
            id: newToast.id ?? ''
          }
        ];
        setTimeout(() => {
          this.onVisibleChange(!newToast.isVisible, newToast.id ?? '');
        }, 5000)
      }
    )
  }

  ngOnInit(): void {
    if (!this.mode) {
      this.mode = modes.primary;
    }
  }

  public close() {
    this.toast.close();
  }

  public onVisibleChange(onvisibilityChanged: boolean, id: string) {
    if (!onvisibilityChanged) {
      this.toasts = this.toasts.filter(el => el.id !== id);
    }
  }
}
