import { Component, Input, OnInit } from '@angular/core';
import { FireStoreService } from '../../../services/fire.store';
import { 
  FormDefinition, Group, Treezor, appRoles, dataTimeFormat,
  RoadMateOrders, GroupedDDL, CardStatus, AccountStatus, error_messages,
  ObjectDefList,
  SettingNames

} from '@roadmate/roadmate-common';
import moment from 'moment';
import { RMToasterService } from '../../../services/toaster.service';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { modes } from '../../../static/notifications';
import { FireFunctionsService, ModalService } from '../../../services';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { ProgressComponent, TextColorDirective } from '@coreui/angular';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';
import { RMMultiSelectModule } from '../../multi-select/multi-select.module';

@Component({
  selector: 'rm-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsComponent, NgClass, ProgressComponent, TextColorDirective, NgIf, TranslateModule, FormsModule, RMMultiSelectModule
  ]
})
export class AddUserComponent extends AbstractEntryComponent<any> implements OnInit {
  @Input()
  public selectedGroups: GroupedDDL[] = [];
  @Input()
  public user: Treezor.AppUser;
  @Input()
  public appUserDef: FormDefinition;

  public groups: Group[] = [];
  public loading = false;
  public products: GroupedDDL[];
  private agentRef: string;
  private companyRef: string;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.selectedGroups = value.selectedGroups ? value.selectedGroups : [];
  }
  constructor(
    private fs: FireStoreService,
    private fa: FireFunctionsService,
    private toast: RMToasterService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.agentRef = this.fs.currentAppUser.agentRef ?? '';
    this.companyRef = this.fs.currentCompany.ref ?? '';
    if (!this.agentRef || !this.companyRef) {
      throw error_messages.PLEASE_SELECT_ONE_COMPANY;
    }
    this.appUserDef = this.fs.getObjectDefinitionByRef(ObjectDefList.appusers);
    if (!this.appUserDef) {
      this.getUserAppDefinition();
    }
    this.getCompanyOrders();
  }

  private async getCompanyOrders () {

    const orders: RoadMateOrders[]  = await this.fs.getClientOrders(this.agentRef, this.companyRef);
    this.products = orders.map(el => {
      return {
        value: el.ref ?? '',
        label: el.product.name,
        ddl: el.beneficiaryGroup ?
        el.beneficiaryGroup.map(item => {
          return {
            value: item.value,
            label: item.label
          }
        }) : []
      }
    });
  }

  public selectionChanged(userGroupIds: GroupedDDL[]) {
    this.selectedGroups = userGroupIds;
  }

  private async getUserAppDefinition() {
    this.appUserDef = await this.fs.getObjectDefinitionByRef(ObjectDefList.appusers);
  }

  public onValidateClick(user: Treezor.AppUser) {
    this.user = user;
  }

  private capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public async doSaveUser() {
    this.loading = true;
    try {
      this.user.companyRef = this.companyRef;
      this.user.agentRef = this.agentRef;
      this.user.agentName = this.fs.currentAgent.name;
      this.user.role = this.user.isAdmin ? appRoles.admin : appRoles.employee;
      this.user.userGroupIds = this.selectedGroups;
      this.user.createdAt = moment().format(dataTimeFormat);
      this.user.email = this.user.email.toLowerCase().trim();
      this.user.firstname = this.capitalize(this.user.firstname);
      this.user.lastname = this.capitalize(this.user.lastname);
      this.user.cardStatus = CardStatus.none;
      this.user.accountStatus = AccountStatus.user_invited;
      const resp = await this.fa.createAppUser(this.user);
      const setting = await this.fs.getCompanyDefaultSetting(
        this.agentRef,
        this.companyRef,
        SettingNames.SEND_WELCOME_EMAIL
      );
      let sendEmail = true;
      if (setting) {
        sendEmail = !!setting.value;
      }
      const message = sendEmail ? 
      `${this.user.title} ${this.user.lastname} a bien été ajouté${this.user.title === 'M' ? '' : 'e'} et va recevoir un email pour activer son compte'` :
      `${this.user.title} ${this.user.lastname} a bien été ajouté${this.user.title === 'M' ? '' : 'e'} mais ne recevra pas d'email car le paramètre d'envoi de email est désactivé`;
      if (resp && !resp.error) {
        this.toast.showToast({
          title: 'Parfait',
          message,
          isVisible: true,
          mode: modes.success,
          duration: 5000
        });
      } else {
        if (resp.message === error_messages.WRONG_FORMAT) {
          this.toast.showToast({
            title: 'Oops',
            message: `Le numéro de téléphone portable saisi semble incorrect (${this.user.mobile}). Veuillez vérifier les informations saisies !`,
            isVisible: true,
            duration: 5000,
            mode: modes.danger
          });
          return;
        }
        this.toast.showToast({
          title: 'Oops',
          message: `Une erreur empêche la création de l'utilisateur. Veuillez recommencer plus tard !`,
          isVisible: true,
          duration: 5000,
          mode: modes.warning
        });
      }
    } catch (e) {
      console.error('Oops. User has not been created', e);
      this.toast.showToast({
        title: 'Oops',
        message: `Un soucis empêche la création de l'utilisateur. Veuillez recommencer plus tard !`,
        isVisible: true,
        duration: 5000,
        mode: modes.warning
      });
    } finally {
      this.loading = false;
      this.close(null);
    }
  }

}
