import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { error_messages, ExpenseLine, FormDefinition,
  ObjectDefList, RoadMateRefundRequest, UserSituation, AutoRefund, 
  RefundRequestType, InstallmentRefund, TransportWhiteList } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, ModalService, RMToasterService } from '../../../services';
import { getTableSettings, transforToIsoDate } from '../../../utils';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { RefundRecapComponent } from './refund-recap/refund-recap.component';
import { ProgressComponent, Tabs2Module } from '@coreui/angular';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';
import { UserSituationComponent } from '../../user-situation/user-situation.component';
import { TransactionsViewerComponent } from '../../transactions-viewer/transactions-viewer.component';
enum Tabs {
  viewrequest = 'viewrequest',
  viewsituation = 'viewsituation',
  whitelists = 'whitelists',
  viewCardTransactions = 'viewCardTransactions',
  recap = 'recap',
}
@Component({
  selector: 'rm-process-refund',
  templateUrl: './process-refund.component.html',
  styleUrls: ['./process-refund.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgClass, NgIf, NgFor,
    RefundRecapComponent,
    ProgressComponent,
    ReactiveFormsComponent, UserSituationComponent, TransactionsViewerComponent,
    Tabs2Module
  ]
})
export class ProcessRefundComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public tabs = Tabs;
  public currentTab = Tabs.recap;
  public objectDef: FormDefinition;
  public autoRefundDef: FormDefinition;
  public autoRefundObject: Partial<AutoRefund>;
  public installementRefundObject: Partial<InstallmentRefund>;
  public previousAutoRefunds: AutoRefund[] = [];
  public previousInstallmentRefunds: InstallmentRefund[] = [];
  public targetObject: RoadMateRefundRequest;
  public amountLeft: number;
  public userExpenseLines: ExpenseLine[];
  public autoRefundSetting: any;
  public loading = false;
  public validating = false;
  public hasFetchedEX = false;
  public userSituation: UserSituation;
  public cardSettings: any;
  public walletSettings: any;
  public explSettings: any;
  public requestsSettings: any;
  public ikvRequestsSettings: any;
  public mobilityAccountSetting: any;
  public byPassCard = false;
  public automationOptAttached = false;
  public installementOptAttached = false;
  public isThereAValidTransportAutoRefund = false;
  public whiteLists: TransportWhiteList[] = [];
  public cardTransactionsSettings: any;
  public cardTransactionsSource: any;
  override set data(value: any) {
    if (!value || !value.targetObject) {
      return;
    } else {
      this.targetObject = value.targetObject;
      if (this.targetObject.requestAutomation) {
        this.getUserAutoRefunds();
      } else if (this.targetObject.requestInstallment) {
        this.getUserInstallmentRefunds();
      }
    }
  }
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private translate: TranslateService,
    private toaster: RMToasterService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.setTable();
  }

  public async getUserCardTransactions() {
    this.loading = true;
    try {
      const response = await this.ff.saGetUserCardTransactions(this.targetObject.agentRef, this.targetObject.companyRef, this.targetObject.email);
      this.cardTransactionsSource = response.result;
      const list = await this.fs.getObjectsList();
      const cardTransactionsDef = JSON.parse(JSON.stringify(list[ObjectDefList.cardTransactions]));
      this.cardTransactionsSettings = await getTableSettings(cardTransactionsDef, this.translate);
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private async getUserInstallmentRefunds () {
    const email = this.targetObject.email;
    this.previousInstallmentRefunds = await this.fs.getPreviousInstallmentRefunds(email);
    this.autoRefundSetting = await getTableSettings(this.autoRefundDef, this.translate);
    const year = (new Date()).getFullYear();
    const isThereAValidTransportInstallmentRefund = this.previousAutoRefunds.find(
      el => el.type === RefundRequestType.REFUND_TRANSPORT_TITLE &&
        el.isActivated && el.isValid
    );
    if (isThereAValidTransportInstallmentRefund) {
      // There Can't be two active Transport Auto refunds
      this.isThereAValidTransportAutoRefund = true;
      return;
    }
    const dayOfMonth = (new Date()).getDate();
    this.installementRefundObject = {
      email: this.targetObject.email,
      agentRef: this.targetObject.agentRef,
      companyRef: this.targetObject.companyRef,
      employeeRef: this.targetObject.uid,
      refundRef: this.targetObject.ref,
      isActivated: false,
      type: this.targetObject.type,
      dayOfTheMonth: dayOfMonth < 5 ? 5 : dayOfMonth >= 27 ? 27 : dayOfMonth,
      contractStartDate: this.targetObject.invoices.date.length >= 10 ? transforToIsoDate(this.targetObject.invoices.date) : '',
      contractEndDate: `${year}-12-31`,
      isValid: false,
      amount: this.targetObject.amount,
      refundHistory: [],
      articleToRefund: this.targetObject.merchantName,
      ref: this.targetObject.ref,
      totalAmountToRefund: this.targetObject.invoices.amount,
      totalAmountRefundedSoFar: 0
    }
  }

  private async getUserAutoRefunds () {
    const email = this.targetObject.email;
    this.previousAutoRefunds = await this.fs.getPreviousAutoRefunds(email);
    this.autoRefundSetting = await getTableSettings(this.autoRefundDef, this.translate);
    const year = (new Date()).getFullYear();
    const isThereAValidTransportAutoRefund = this.previousAutoRefunds.find(
      el => el.type === RefundRequestType.REFUND_TRANSPORT_TITLE &&
        el.isActivated && el.isValid
    );
    if (isThereAValidTransportAutoRefund) {
      // There Can't be two active Transport Auto refunds
      this.isThereAValidTransportAutoRefund = true;
      return;
    }
    const dayOfMonth = (new Date()).getDate();
    this.autoRefundObject =  {
      email: this.targetObject.email,
      agentRef: this.targetObject.agentRef,
      companyRef: this.targetObject.companyRef,
      employeeRef: this.targetObject.uid,
      refundRef: this.targetObject.ref,
      isActivated: false,
      type: this.targetObject.type,
      dayOfTheMonth: dayOfMonth >= 27 ? 27 : dayOfMonth,
      contractStartDate: this.targetObject.invoices.date.length >= 10 ? transforToIsoDate(this.targetObject.invoices.date) : '',
      contractEndDate: `${year}-12-31`,
      isValid: false,
      amount: this.targetObject.amount,
    };
    
  }

  private async setTable() {
    const list = await this.fs.getObjectsList();
    this.objectDef = JSON.parse(JSON.stringify(list[ObjectDefList.refundRequests]));
    this.autoRefundDef = JSON.parse(JSON.stringify(list[ObjectDefList.autoRefunds]))
    const cardDef = JSON.parse(JSON.stringify(list[ObjectDefList.cardSituation]));
    this.cardSettings = await getTableSettings(cardDef, this.translate);
    const walletDef = JSON.parse(JSON.stringify(list[ObjectDefList.userWalletSituation]));
    this.walletSettings = await getTableSettings(walletDef, this.translate);
    const explDef = JSON.parse(JSON.stringify(list[ObjectDefList.expenselines]));
    this.explSettings = await getTableSettings(explDef, this.translate);
    const ikvReq = JSON.parse(JSON.stringify(list[ObjectDefList.ikvRequest]));
    this.ikvRequestsSettings = await getTableSettings(ikvReq, this.translate);
    const req = JSON.parse(JSON.stringify(list[ObjectDefList.refundRequests]));
    this.requestsSettings = await getTableSettings(req, this.translate);
    const mobAccount = JSON.parse(JSON.stringify(list[ObjectDefList.mobilityAccount]));
    this.mobilityAccountSetting = await getTableSettings(mobAccount, this.translate);
    //mobility-account
  }

  public setTab(tab: Tabs) {
    this.currentTab = tab;
    if (tab === Tabs.viewsituation && !this.hasFetchedEX) {
      this.getStatus();
    }
  }

  public saveAutomationObject(automationOpt: AutoRefund) {
    this.autoRefundObject = automationOpt;
    this.autoRefundObject.validatedBy = this.fs.currentAppUser.email;
    this.autoRefundObject.validationDate = (new Date()).toISOString();
    this.automationOptAttached = true;
  }

  public saveInstallementObject(installementOpt: InstallmentRefund) {
    this.installementRefundObject = {
      ...this.installementRefundObject,
      ...installementOpt
    };
    this.installementRefundObject.validatedBy = this.fs.currentAppUser.email;
    this.installementRefundObject.validationDate = (new Date()).toISOString();
    this.installementOptAttached = true;
  }

  private async getStatus() {
    this.loading = true;
    try {
      const {agentRef, companyRef, email} = this.targetObject;
      const response = await this.ff.adminGetUserStatus(agentRef, companyRef, email);
      if (response?.result) {
        this.userSituation = response.result;
        this.whiteLists = this.userSituation.whitelists as TransportWhiteList[];
      }
      const exp = this.userSituation.expenseLines.find(el => el.ref === this.targetObject.expenseLine);
      if (exp) {
        this.amountLeft = exp.authorizedBalance;
      }
      this.hasFetchedEX = true;
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
      // console.log(this.userSituation);
    }
  }

  public async validateRequest() {
    this.validating = true;
    try {
      if (!this.targetObject.ref) {
        this.toaster.showGenericError('Il manque la ref de la demande');
        this.close(false);
        return;
      }
      await this.updateRequest(this.targetObject);
      const response = await this.ff.processUserRefundTransfer(this.targetObject.ref, true, this.targetObject.type, this.byPassCard);
      if (response.message === error_messages.OK) {
        this.toaster.showSuccessToast(`Le remboursement s'est bien déroulé.`);
        if (this.targetObject.requestAutomation && this.autoRefundObject && this.autoRefundObject.isValid  && this.autoRefundObject.isActivated) {
          await this.fs.addNewAutoRefund(this.autoRefundObject as AutoRefund);
          this.toaster.showSuccessToast(`La demande de remboursement automatique a été enregistrée.`);
        } else if (this.targetObject.requestInstallment && this.installementOptAttached && this.installementRefundObject.isValid && this.installementRefundObject.isActivated) {
          const refundUpToDate = await this.fs.getSARefundRequests(this.targetObject.ref);
          if (refundUpToDate?.ref) {
            this.installementRefundObject.refundHistory = [
              {
                refundAmount: refundUpToDate.amount,
                createdAt: refundUpToDate.createdAt,
                updatedAt: refundUpToDate.createdAt,
                payoutRef: `${refundUpToDate.payoutRef}`,
                transactionId: `${refundUpToDate.paymentId}`,
                month: (new Date()).getMonth(),
                refundRef: refundUpToDate.ref,
                processDate: (new Date()).toISOString(),
              }
            ];
            await this.fs.addNewInstallementRefund(this.installementRefundObject as InstallmentRefund);
            this.toaster.showSuccessToast(`La demande de remboursement par échéance a été enregistrée.`);
          }
        }
        this.close(true);
      } else {
        this.toaster.showGenericError(response.message);
        this.close(false);
      }
    } catch (e) {
      console.error('validateRequest', e);
    } finally {
      this.validating = false;
    }
  }

  public async rejectRequest() {
    this.validating = true;
    try {
      if (!this.targetObject.ref) {
        this.toaster.showGenericError('Il manque la ref de la demande');
        this.close(false);
        return;
      }
      await this.updateRequest(this.targetObject);
      const response = await this.ff.processUserRefundTransfer(this.targetObject.ref, false, this.targetObject.type);
      if (response.message === error_messages.OK) {
        this.toaster.showSuccessToast(`La tâche a bien été traitée.`);
        this.close(true);
      } else {
        this.toaster.showGenericError(response.message);
        this.close(false);
      }
    } catch (e) {
      console.error('validateRequest', e);
    } finally {
      this.validating = false;
    }
  }

  public openFile(url: string) {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public async updateRequest(request: RoadMateRefundRequest) {
    if (!request.ref) {
      return;
    }
    this.loading = true;
    try {
      await this.fs.updateRefundRequest(request);
      this.targetObject = request;
      this.toaster.showSuccessToast('La demande de remboursement a bien été mise à jour.');
    } catch (e) {
      console.error('Enable to update request', e);
      this.toaster.showGenericError('La mise à jour de la demande a échoué.');
    } finally {
      this.loading = false;
    }
  }
}
