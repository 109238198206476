<h5 *ngIf="nbUsersSelected">
  {{'you_have_selected' | translate}} {{nbUsersSelected}}
  <span *ngIf="nbUsersSelected === 1">{{'added_user' | translate}}</span>
  <span *ngIf="nbUsersSelected > 1">{{'added_users' | translate}}</span>
</h5>
<div>
</div>
<div>
  <button 
    class="btn btn-primary" type="button"
    [disabled]="!selectedGroup"
    (click)="validateSelection()"
  >
    {{'validate' | translate}}
  </button>
  <button 
    class="btn btn-primary"
    type="button"
    (click)="close(null)"
  >
    {{'cancele' | translate}}
  </button>
</div>
