<div class="toast-container">
  <!-- <c-toaster placement="top-end" position="absolute"> -->
    <c-toast 
      *ngFor="let toast of toasts"
      [autohide]="true" 
      [fade]="true" 
      [visible]="toast.visibile"
      [color]="toast.color"
      class="push-down"
      (visibleChange)="onVisibleChange($event, toast.id)"
    >
      <c-toast-header [closeButton]="true">
        {{toast.title| translate}}
        <!-- <small class="ms-auto">{{'just-now' | translate}}</small> -->
      </c-toast-header>
      <c-toast-body>
        <p [innerHTML]="toast.message | translate"></p>
      </c-toast-body>
    </c-toast>
  <!-- </c-toaster> -->
</div>

