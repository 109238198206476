import { Component, OnInit } from '@angular/core';
import { isEmail, SelectableHolder } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayMonthAndDatePipe } from '../../pipes/display-human-date.pipe';
import { ModalService } from '../../../services';
import { ColComponent, RowComponent, TableDirective } from '@coreui/angular';
import { cilCheck, cilXCircle } from '@coreui/icons'
import { IconDirective } from '@coreui/icons-angular';

@Component({
  selector: 'app-confirm-user-deletion',
  templateUrl: './confirm-user-deletion.component.html',
  styleUrls: ['./confirm-user-deletion.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgIf, NgFor, DisplayMonthAndDatePipe, RowComponent, ColComponent, TableDirective, IconDirective
  ]
})
export class ConfirmUserDeletionComponent extends AbstractEntryComponent<boolean> implements OnInit {
  emailList: SelectableHolder<string>[] = [];
  icons = {
    cilCheck, cilXCircle
  }
  schedule: boolean;
  startDate: string;
  notifyUsers: boolean;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.setEmailsSelectables(value.emails);
    this.schedule = !!value.schedule;
    this.startDate = value.startDate;
    this.notifyUsers = !!value.notifyUsers
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void { }

  private setEmailsSelectables(emails: string[]) {
    this.emailList = emails.map(el => {
      return {
        isSelected: isEmail(el),
        data: el
      }
    });
  }

  public confirm() {
    this.close(true);
  }
}
