import { Component, OnInit } from '@angular/core';
import { addDays, RoadMateTimeZone, UserDeletionImport } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';
import moment from 'moment-timezone';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '@coreui/angular-pro';
import { FormCheckComponent, FormCheckInputDirective, FormCheckLabelDirective } from '@coreui/angular';
import { NgIf } from '@angular/common';
import { ModalService } from '../../../services';

@Component({
  selector: 'app-import-delete-users',
  templateUrl: './import-delete-users.component.html',
  styleUrls: ['./import-delete-users.component.scss'],
  standalone: true,
  imports: [
    FormsModule, TranslateModule, DatePickerComponent, FormCheckInputDirective, FormCheckComponent, FormCheckLabelDirective,
    NgIf
  ]
})
export class ImportDeleteUsersComponent extends AbstractEntryComponent<UserDeletionImport> implements OnInit {
  emails = '';
  date = '';
  startDate: Date;
  minStartDate: Date;
  schedule = false;
  doitNow = true;
  public fr = RoadMateTimeZone.localeFr;
  public notifyUsers = false;
  constructor(
    modalService: ModalService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.startDate = addDays(new Date(), 1);
    this.minStartDate = addDays(new Date(), 1);
  }

  public submit() {
    this.close({
      emails: this.emails.replace(/\r\n/g,'\n').split('\n'),
      schedule: this.schedule,
      deleteOneNow: this.doitNow,
      startDate: `${moment(this.startDate).format('YYYY-MM-DD')}`,
      notifyUsers: this.notifyUsers
    });

  }

  public updateToggles(index: number) {
    if (index === 1) {
      setTimeout(() => {
        this.schedule = !this.doitNow
      }, 100);
      return;
    }
    setTimeout(() => {
      this.doitNow = !this.schedule
    }, 100);
  }
}
