import { Component, Input, OnInit } from '@angular/core';
import { FireFunctionsService, FireStoreService } from '../../services';
import { ObjectDefList, Treezor } from '@roadmate/roadmate-common';
import { getTableSettings } from '../../utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Ng2SmartTableComponent } from '../migration/tables/ng2-smart-table.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'rm-transactions-viewer',
  templateUrl: './transactions-viewer.component.html',
  styleUrls: ['./transactions-viewer.component.scss'],
  standalone: true,
  imports: [
    Ng2SmartTableComponent, NgIf, TranslateModule
  ]
})
export class TransactionsViewerComponent implements OnInit {
  @Input() public agentRef: string;
  @Input() public companyRef: string;
  @Input() public email: string;
  public loading = false;
  public cardTransactionsSettings: any;
  public cardTransactionsSource: Treezor.Card.PaymentHistory[] = [];
  constructor(
    private ff: FireFunctionsService,
    private translate: TranslateService,
    private fs: FireStoreService
  ) { }

  ngOnInit(): void { }

  public async getUserCardTransactions() {
    this.loading = true;
    try {
      const response = await this.ff.saGetUserCardTransactions(this.agentRef, this.companyRef, this.email);
      if (!response?.result) {
        console.error('No card transactions found');
        return;
      }
      this.cardTransactionsSource = response.result;
      const list = await this.fs.getObjectsList();
      const cardTransactionsDef = JSON.parse(JSON.stringify(list[ObjectDefList.paymentHistory]));
      this.cardTransactionsSettings = await getTableSettings(cardTransactionsDef, this.translate);
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }
}
