import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleNotificationWrapper, DropDownListOption, IKV, RefundRequestType, RoadMateMath, saLists, SettingNames, InternalNote, RoadMateRefundRequest, LLM, AiInteractionType, LLMThreshold } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, RMToasterService } from '../../../../services';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CurrencyPipe, DatePipe, MonthZeroIndexPipe, DisplayMonthAndDatePipe, IsoToFrenchDateTimePipe, FileExtensionImagePipe, TruncPipe, DisplayDDLValuePipe } from '../../../pipes';
import { FormsModule } from '@angular/forms';
import { RMMultiSelectModule } from '../../../multi-select/multi-select.module';
import { cilCopy, cilEnvelopeClosed } from '@coreui/icons';
import { IconDirective } from '@coreui/icons-angular';
import { ButtonDirective, ButtonGroupComponent, ColComponent, DropdownComponent, DropdownDividerDirective, DropdownItemDirective, DropdownMenuDirective, DropdownModule, DropdownToggleDirective, FormControlDirective, FormLabelDirective, InputGroupComponent, InputGroupTextDirective, RowComponent, SpinnerModule, ThemeDirective } from '@coreui/angular';
// import { SingleSelectComponent } from '../../../multi-select/single-select/single-select.component';



@Component({
  selector: 'rm-refund-recap',
  templateUrl: './refund-recap.component.html',
  styleUrls: ['./refund-recap.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgClass, CurrencyPipe,
    DisplayMonthAndDatePipe, IsoToFrenchDateTimePipe, TranslateModule, DatePipe, FileExtensionImagePipe, TruncPipe, NgFor, MonthZeroIndexPipe, DisplayDDLValuePipe,
    FormsModule, RMMultiSelectModule, IconDirective,
    RowComponent, ColComponent, InputGroupComponent, FormLabelDirective, InputGroupTextDirective,
    ButtonDirective,SpinnerModule, 
    DropdownModule, FormControlDirective,
    
    ButtonGroupComponent,
    ThemeDirective,
    DropdownComponent,
    DropdownToggleDirective,
    DropdownMenuDirective,
    DropdownItemDirective,
    // RouterLink,
    DropdownDividerDirective
  ]
})
export class RefundRecapComponent implements OnInit {
  @Input() public request: IKV;
  @Output()
  public requestEmitter = new EventEmitter<RoadMateRefundRequest>();
  @Output()
  public closeEmitter = new EventEmitter<boolean>();
  public isMonthly = false;
  public icons = {
    cilCopy,
    cilEnvelopeClosed
  }
  public get llms(): typeof LLM {
    return LLM;
  }
  public merchantsList: DropDownListOption[] = [];
  public htmlSnippetsList: DropDownListOption[] = [];
  public percentage = 0;
  public companyRefunRate = -1;
  public isRTT = false;
  public isIKV = false;
  public notes: InternalNote[] = [];
  public newNote = '';
  public newMessage = '';
  public newMessageSubject = 'Rappel';
  public loading = false;
  public isAnnual = false;
  public isAnnualNavigo = false;
  public isImaginaire = false;
  public missingSettingForCompany = false;
  public inconsistentAnnualRefundAmountMessage = '';
  public notifications: GoogleNotificationWrapper[] = [];
  public messageCanals = [{
    label: 'Notification',
    value: 'notification'
  }, {
    label: 'sms',
    value: 'sms'
  }, {
    label: 'email',
    value: 'email'
  }, {
    label: 'support',
    value: 'support'
  }];
  public selectedMessageCanal = 'notification';
  public destinationRefundRef = '';
  public askingAi = false;
  constructor(
    private fs: FireStoreService,
    private fn: FireFunctionsService,
    private toast: RMToasterService,
  ) { }

  ngOnInit(): void {
    if (!this.request) {
      return;
    }
    this.merchantsList = this.fs.getListByName('merchants');
    const snippets = this.fs.salists.find(el => el.name === saLists.htmlSnippets);
    if (snippets?.list?.length) {
      this.htmlSnippetsList = snippets.list;
    }
    this.isMonthly = this.request.type === RefundRequestType.REFUND_TRANSPORT_TITLE || this.request.type === RefundRequestType.REFUND_REQUEST_RENTAL;
    this.isRTT = this.request.type === RefundRequestType.REFUND_TRANSPORT_TITLE;
    if (this.isRTT){
      this.percentage = Math.floor(
        RoadMateMath.normalize(
          this.request.amount / this.request.invoices.amount * 100
        )
      );
      this.getCompanyRefundRate();
    }
    this.isIKV = this.request.type === RefundRequestType.REFUND_REQUEST_IKV;
    this.getUserNotes();
    this.getEmployeeNotifications();
    if (this.request.type === RefundRequestType.REFUND_TRANSPORT_TITLE && this.request.year >= 2024) {
      this.checkIfNavigAnnuel();
    }
  }

  public async askAi(llm: LLM) {
    
    if (!this.newMessage) {
      this.toast.showGenericError('Vous devez d\'abord écrire un message à améliorer.');
      return;
    }
    this.askingAi = true;
    try {
      const response = await this.fn.askai({
        context: 'about_refund_request',
        interactionType: ['notification', 'sms'].indexOf(this.selectedMessageCanal) > -1 ? AiInteractionType.text_correction : AiInteractionType.text_correction_in_html,
        model: '',
        llm,
        prePrompt: '',
        prompt: this.newMessage,
        threshold: LLMThreshold.DEFAULT
      });
      if (!response.error && response.result) {
        this.newMessage = response.result;
        this.toast.showSuccessToast('Message amélioré et remis dans le champ de texte.');
      } else {
        this.toast.showGenericError(`${llm} n'a pas pu répondre à votre demande. ${response.result}`);
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
      this.askingAi = false;
    }
  }

  public async copy(value: string) {
    await navigator.clipboard.writeText(value);
    this.toast.showSuccessToast('Copié dans le presse-papier');
  }

  public async mergeRequest() {
    if (!this.destinationRefundRef || !this.request.ref) {
      this.toast.showGenericError();
      return;
    }
    this.loading = true;
    try {
      const response = await this.fn.saMergeRefundRequests(this.request.ref, this.destinationRefundRef);
      if (!response.error) {
        this.toast.showSuccessToast(response.result as string);
        this.closeEmitter.emit(true);
      } else {
        this.toast.showGenericError(response.result);
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private async getEmployeeNotifications() {
    const {agentRef, companyRef, uid} = this.request;
    if (!agentRef || !companyRef || !uid) {
      return;
    }
    const notifications = await this.fs.getEmployeeNotifications(agentRef, companyRef, uid);
    this.notifications = notifications.sort((a, b) => { return b.createdAt.localeCompare(a.createdAt) });

  }

  public async checkIfNavigAnnuel (fixIt = false) {
    this.inconsistentAnnualRefundAmountMessage = '';
    if (this.request.documentaiResponses?.length) {
      const [doc] = this.request.documentaiResponses;
      if (doc.supplier_name && doc.supplier_name.toLocaleLowerCase() === 'navigo annuel') {
        const nbMonthToRefundSetting = await this.fs.getCompanyDefaultSetting(
          this.request.agentRef,
          this.request.companyRef,
          SettingNames.NUMBER_OF_MONTH_REFUND_ANNUAL_TRANSPORT
        );
        const percentage = await this.fs.getCompanyDefaultSetting(
          this.request.agentRef,
          this.request.companyRef,
          SettingNames.COMPANY_TRANSPORT_REFUND_RATE
        );
        if (!nbMonthToRefundSetting || !percentage) {
          this.missingSettingForCompany = true;
          return;
        }
        const annualAmount = 950.4;
        // const monthlyAmount = 86.4;
        const refundRate = parseFloat(`${percentage.value}`);
        const nbMonths = parseFloat(`${nbMonthToRefundSetting.value}`);
        const invoiceAmount = RoadMateMath.normalize(annualAmount/nbMonths);
        const refundAmount = RoadMateMath.normalize(invoiceAmount * refundRate / 100);
        if (this.request.amount !== refundAmount || this.request.invoices.amount !== invoiceAmount) {
          this.inconsistentAnnualRefundAmountMessage = `Le montant du remboursement ne respecte pas les règles de l'entreprise. Le montant à rembourser devrait être ${refundAmount}€ contre ${this.request.amount}€.<br>Pour info, cette entreprise est paramétrée pour un remboursement sur ${nbMonths} mois à ${refundRate}%`;
          if (fixIt) {
            this.request.amount = refundAmount;
            this.request.invoices.amount = invoiceAmount;
            this.request.description = `Votre entreprise vous rembourse ${refundRate}% du montant annuel de votre abonnement Navigo sur ${nbMonths} mensualités, soit ${annualAmount}€ / ${nbMonths} mois x ${refundRate}% = ${refundAmount}€.<br>Pour plus d'information, consulter <a href="https://www.iledefrance-mobilites.fr/tarifs-transports-ile-de-france-mobilites-2023" target="_blank">la page des tarifs Île-de-France Mobilités 2024</a>.`
            this.requestEmitter.emit(this.request);
            this.inconsistentAnnualRefundAmountMessage = '';
          }
        }
      }
    }
  }

  private async getUserNotes() {
    const notes = await this.fs.getEmployeeInternalNotes(this.request.agentRef, this.request.companyRef, this.request.uid);
    this.notes = notes.sort((a, b) => { return (a.createdAt ?? '').localeCompare(b.createdAt ?? '') });
  }

  public async sendMessage() {
    this.loading = true;
    try {
      const response = await this.fn.saSendMessageToUser(this.request.email, this.newMessageSubject, this.newMessage, this.selectedMessageCanal);
      if (!response.error) {
        this.toast.showSuccessToast(response.result);
      } else {
        this.toast.showGenericError();
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
      await this.getEmployeeNotifications();
      this.newMessage = '';
      this.newMessageSubject = 'Rappel';
    }

  }

  public async addNote() {
    if (!this.newNote) {
      return;
    }
    this.loading = true;
    try {
      const note: InternalNote = {
        content: this.newNote,
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
        agentRef: this.request.agentRef,
        companyRef: this.request.companyRef,
        uid: this.request.uid,
        email: this.request.email,
        aboutObject: 'refund-request',
        objetRef: this.request.ref ?? '',
        markedAsNonRelevant: false,
        createdBy: this.fs.currentAppUser.email
      }
      await this.fs.addEmployeeInternalNote(
        this.request.agentRef,
        this.request.companyRef,
        this.request.uid,
        note
      );
      this.newNote = '';
      await this.getUserNotes();
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private async getCompanyRefundRate() {
    if (!this.request) {
      return;
    }
    const {companyRef, agentRef} = this.request;
    if (!companyRef || !agentRef) {
      return;
    }
    const setting = await this.fs.getCompanyDefaultSetting(agentRef, companyRef, SettingNames.COMPANY_TRANSPORT_REFUND_RATE);
    if (!setting) {
      return;
    }
    const rate = parseInt(setting.value, 10);
    if (!isNaN(rate)) {
      this.companyRefunRate = rate;
    }
  }

  public openFile(url: string) {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  public changeCanal(item: DropDownListOption) {
    this.selectedMessageCanal = item.value;
  }
}
