import { Component, Input, OnInit } from '@angular/core';
import { actions, fileStoringWorkflows } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressComponent, TextColorDirective } from '@coreui/angular';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';
import { NgFor, NgIf } from '@angular/common';
import { ModalService } from '../../../services';

@Component({
  selector: 'rm-edit-object',
  templateUrl: './edit-object.component.html',
  styleUrls: ['./edit-object.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, ProgressComponent, TextColorDirective, ReactiveFormsComponent, NgIf, NgFor
  ]
})
export class UpdateObjectComponent extends AbstractEntryComponent<any> implements OnInit {
  @Input()
  public targetObject: any;
  @Input()
  public objectDef: any;
  @Input()
  public isReadOnly = false;
  @Input()
  public description = '';
  @Input()
  public btns: {label: string, value:string}[] =[];
  @Input()
  public fileStoringWorkflow: fileStoringWorkflows;
  override set data(value: any) {
    if (!value || !value.objectDef) {
      return;
    } else {
      this.objectDef = value.objectDef;
      this.targetObject = value.targetObject;
      this.isReadOnly = !!value.isReadOnly;
      this.description = value.description;
      this.fileStoringWorkflow = value.fileStoringWorkflow ?? fileStoringWorkflows.assets;
      if (value.btns) {
        this.btns = value.btns;
      }
    }
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void { }

  public sendObjectToSender(objectUpdated: any) {
    this.close(objectUpdated);
  }

  public btnClick(btn: {label: string, value:string}) {
    this.close({
      action: actions.syncCompany,
      data: this.targetObject
    });
  }
}
