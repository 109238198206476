import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RoadMateTransfer } from '@roadmate/roadmate-common';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe } from '../../pipes';
import { ModalService } from '../../../services';

@Component({
  selector: 'rm-confirm-budget-adjustment',
  templateUrl: './confirm-budget-adjustment.component.html',
  standalone: true,
  imports: [
    TranslateModule, CurrencyPipe
  ]
})
export class ConfirmBudgetAdjustmentComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public transfer: Partial<RoadMateTransfer>;
  public email = '';
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.transfer = value.transfer;
    this.email = value.email;
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void { }

}
