<c-tabs [activeItemKey]="allTabs.newuser" (activeItemKeyChange)="setTab($any($event))">
  <c-tabs-list variant="underline-border">
    <button cTab [itemKey]="allTabs.newuser">{{allTabs.newuser | translate}}</button>
    <button cTab [itemKey]="allTabs.existinguser">{{allTabs.existinguser | translate}}</button>
  </c-tabs-list>
  <c-tabs-content>
    <c-tab-panel class="p-3" [itemKey]="allTabs.newuser" [transition]="false">
      <h5>{{'add_new_am'|translate}}</h5>
      <p>{{'user_should_not_be_already_created'|translate}}</p>
      <rm-reactive-forms
        [objectDefinition]="appUserDef"
        [setLoading]="loading"
        (saveEmitter)="saveAppUser($event)"
      >
      </rm-reactive-forms>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="allTabs.existinguser" [transition]="false">
      <h5>
        {{'assign_am_priviledge_to_existing_user'|translate}}
      </h5>
      <p>
        {{'input_email_to_grant_access' | translate}}
      </p>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-append">
            <span class="btn btn-primary" type="button">
              <svg [cIcon]="icons.cilAt" color="success" size="sm" title="Email"></svg>
            </span>
          </div>
          <input class="form-control"  type="email" [(ngModel)]="email">
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary" type="button" (click)="grantAccess()" [disabled]="loading">
          <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
          {{'confirm' | translate}}
        </button>
      </div>
    </c-tab-panel>
  </c-tabs-content>
</c-tabs>
