import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { FireFunctionsService, ModalService, RMToasterService } from '../../../services';
import { error_messages } from '@roadmate/roadmate-common';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ValidationCardComponent } from './validation-card/validation-card.component';
@Component({
  selector: 'rm-verify-token',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgSwitch, NgSwitchCase, TranslateModule, FormsModule, ValidationCardComponent
  ]
})
export class VerifyTokenComponent extends AbstractEntryComponent<any> implements OnInit {
  public description: string;
  public token = '';
  public img = '/assets/svg/token_validation.svg';
  public tokenLength = 4;
  public placeholder = 'XXXX';
  public showComponentAsImage = '';
  public timeout = false;
  public requestRef: string;
  public loading = false;
  public otpSentByEmail = false;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.description = value.description;
    if (value.img) {
      this.img = value.img
    }
    if (value.requestRef) {
      this.requestRef = value.requestRef;
    }
    if (value.tokenLength && typeof value.tokenLength === 'number') {
      this.tokenLength = value.tokenLength;
      this.placeholder = Array(this.tokenLength).fill({}).map(el => 'X').join('');
    }
    if (value.showComponentAsImage) {
      this.showComponentAsImage = value.showComponentAsImage;
    }
  }
  constructor(
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.timeout = true;
    } , 10000);
  }

  public isValidToken(value: string) {
    if (!value || typeof value !== 'string') {
      return false;
    }
    if (this.tokenLength === 4) {
      return /^\d{4}$/.test(value);
    } else if (this.tokenLength === 9) {
      return /^\d{9}$/.test(value);
    }
    return false;
  }

  public async resendCode() {
    if (!this.requestRef) {
      return;
    }
    this.loading = true;
    try {
      const response = await this.ff.requestOtpByEmail(this.requestRef);
      if (response.message === error_messages.OK) {
        this.otpSentByEmail = true;
      } else if (response.message === error_messages.NOT_ALLOWED) {
        this.toast.showGenericError('you_have_to_confirm_your_phone_before_you_can_resend_the_code');
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
