<c-tabs [activeItemKey]="currentTab" *ngIf="ready" (activeItemKeyChange)="setTab($any($event))">
  <c-tabs-list variant="underline-border">
    <button cTab [itemKey]="'kyb'">Le Kyb</button>
    <button cTab [itemKey]="'beneficiaries'">{{'beneficiaries' | translate}}</button>
    <button cTab [itemKey]="'documents'">{{'documents' | translate}}</button>
  </c-tabs-list>
  <c-tabs-content>
    <c-tab-panel class="p-3" [itemKey]="'kyb'" [transition]="false">
      <rm-reactive-forms
        [objectToUpdate]="kyb"
        [objectDefinition]="kybFormDefinition"
        [displayOnly]="readonly"
        (saveEmitter)="sendObjectToSender($event)"
      >
      </rm-reactive-forms>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="'beneficiaries'" [transition]="false">
      <rm-view-kyb-beneficiaries [kyb]="kyb"></rm-view-kyb-beneficiaries>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="'documents'" [transition]="false">
      <rm-view-uploaded-documents [kyb]="kyb"></rm-view-uploaded-documents>
    </c-tab-panel>
  </c-tabs-content>
</c-tabs>