import { Component, computed, DestroyRef, inject, Input } from '@angular/core';
import {
  // AvatarComponent,
  BadgeComponent,
  BreadcrumbRouterComponent,
  ColorModeService,
  ContainerComponent,
  DropdownComponent,
  DropdownDividerDirective,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  INavData,
  NavItemComponent,
  NavLinkDirective,
  ProgressBarDirective,
  ProgressComponent,
  SidebarToggleDirective,
  TextColorDirective,
  ThemeDirective
} from '@coreui/angular';
import { NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { IconDirective } from '@coreui/icons-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay, filter, map, tap } from 'rxjs/operators';
import { FireAuthService, FireStoreService, MessengerService } from '../../../services';
import { actions, appRoles, AppRoles, rm_storageKeys, Treezor } from '@roadmate/roadmate-common';
import { adminLinks, agentLinks, amCompanyNotSelected, appRoutes, employeeNavLinks, loggedInNotConfigured, loggedInNotValidated, superadminLinks } from '../../../static';
import { TranslateModule } from '@ngx-translate/core';
import { cilAccountLogout, cilChatBubble, cilSettings, cilTransfer, cilUser } from '@coreui/icons';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
  standalone: true,
  imports: [ContainerComponent, HeaderTogglerDirective, SidebarToggleDirective, 
    IconDirective, HeaderNavComponent, NavItemComponent, NavLinkDirective, 
    RouterLink, RouterLinkActive, NgTemplateOutlet, BreadcrumbRouterComponent, 
    ThemeDirective, DropdownComponent, DropdownToggleDirective, TextColorDirective, 
    DropdownMenuDirective, DropdownHeaderDirective, DropdownItemDirective, BadgeComponent, 
    DropdownDividerDirective, ProgressBarDirective, ProgressComponent, 
    NgStyle, NgIf, NgFor, TranslateModule
  ]
})
export class DefaultHeaderComponent extends HeaderComponent {
  public ready = false;
  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  readonly #colorModeService = inject(ColorModeService);
  readonly colorMode = this.#colorModeService.colorMode;
  readonly #destroyRef: DestroyRef = inject(DestroyRef);
  public initials = '';
  public supportUrl = '';
  public navLinks: INavData[] = [];
  readonly colorModes = [
    { name: 'light', text: 'Clair', icon: 'cilSun' },
    { name: 'dark', text: 'Sombre', icon: 'cilMoon' },
    { name: 'auto', text: 'Auto', icon: 'cilContrast' }
  ];

  readonly icons = computed(() => {
    const currentMode = this.colorMode();
    return this.colorModes.find(mode=> mode.name === currentMode)?.icon ?? 'cilSun';
  });
  myIcons = {
    cilChatBubble
  }
  public loginAs = '';
  public isAdmin = false;
  public userAppRole = '';
  public isSuperAdmin = false;
  public isAgent = false;
  public isEmployee = false;
  public isAccountManager = false;
  public headerTitle = '';
  constructor(
    private messenger: MessengerService,
    private fs: FireStoreService,
    private auth: FireAuthService
  ) {
    super();
    this.#colorModeService.localStorageItemName.set('roadmate-dashboard-theme-default');
    this.#colorModeService.eventName.set('ColorSchemeChange');
    if (!this.fs.currentAppUser) {
      const sub = this.messenger.parcel.subscribe(
        parcel => {
          if (parcel.action === actions.user && parcel.data?.firstname && parcel.data?.lastname) {
            this.initials = parcel.data.firstname.charAt(0) + parcel.data.lastname.charAt(0);
            sub.unsubscribe();
          }
        }
      )
    } else {
      this.initials = this.fs.currentAppUser.firstname.charAt(0) + this.fs.currentAppUser.lastname.charAt(0);
    }
    this.#activatedRoute.queryParams
      .pipe(
        delay(1),
        map(params => <string>params['theme']?.match(/^[A-Za-z0-9\s]+/)?.[0]),
        filter(theme => ['dark', 'light', 'auto'].includes(theme)),
        tap(theme => {
          this.colorMode.set(theme);
          this.messenger.parcel.next({ action: actions.theme, data: theme });
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  @Input() sidebarId: string = 'sidebar1';

  public changeTheme(theme: string) {
    this.messenger.parcel.next({ action: actions.theme, data: theme });
    this.colorMode.set(theme);
  }

  public newMessages = [
    {
      id: 0,
      from: 'Jessica Williams',
      avatar: '7.jpg',
      status: 'success',
      title: 'Urgent: System Maintenance Tonight',
      time: 'Just now',
      link: 'apps/email/inbox/message',
      message: 'Attention team, we\'ll be conducting critical system maintenance tonight from 10 PM to 2 AM. Plan accordingly...'
    },
    {
      id: 1,
      from: 'Richard Johnson',
      avatar: '6.jpg',
      status: 'warning',
      title: 'Project Update: Milestone Achieved',
      time: '5 minutes ago',
      link: 'apps/email/inbox/message',
      message: 'Kudos on hitting sales targets last quarter! Let\'s keep the momentum. New goals, new victories ahead...'
    },
    {
      id: 2,
      from: 'Angela Rodriguez',
      avatar: '5.jpg',
      status: 'danger',
      title: 'Social Media Campaign Launch',
      time: '1:52 PM',
      link: 'apps/email/inbox/message',
      message: 'Exciting news! Our new social media campaign goes live tomorrow. Brace yourselves for engagement...'
    },
    {
      id: 3,
      from: 'Jane Lewis',
      avatar: '4.jpg',
      status: 'info',
      title: 'Inventory Checkpoint',
      time: '4:03 AM',
      link: 'apps/email/inbox/message',
      message: 'Team, it\'s time for our monthly inventory check. Accurate counts ensure smooth operations. Let\'s nail it...'
    },
    {
      id: 3,
      from: 'Ryan Miller',
      avatar: '4.jpg',
      status: 'info',
      title: 'Customer Feedback Results',
      time: '3 days ago',
      link: 'apps/email/inbox/message',
      message: 'Our latest customer feedback is in. Let\'s analyze and discuss improvements for an even better service...'
    }
  ];

  public newNotifications = [
    { id: 0, title: 'New user registered', icon: 'cilUserFollow', color: 'success' },
    { id: 1, title: 'User deleted', icon: 'cilUserUnfollow', color: 'danger' },
    { id: 2, title: 'Sales report is ready', icon: 'cilChartPie', color: 'info' },
    { id: 3, title: 'New client', icon: 'cilBasket', color: 'primary' },
    { id: 4, title: 'Server overloaded', icon: 'cilSpeedometer', color: 'warning' }
  ];

  public newStatus = [
    { id: 0, title: 'CPU Usage', value: 25, color: 'info', details: '348 Processes. 1/4 Cores.' },
    { id: 1, title: 'Memory Usage', value: 70, color: 'warning', details: '11444GB/16384MB' },
    { id: 2, title: 'SSD 1 Usage', value: 90, color: 'danger', details: '243GB/256GB' }
  ];

  public newTasks = [
    { id: 0, title: 'Upgrade NPM', value: 0, color: 'info' },
    { id: 1, title: 'ReactJS Version', value: 25, color: 'danger' },
    { id: 2, title: 'VueJS Version', value: 50, color: 'warning' },
    { id: 3, title: 'Add new layouts', value: 75, color: 'info' },
    { id: 4, title: 'Angular Version', value: 100, color: 'success' }
  ];

  onSidebarToggle(state: any) {
    console.log('Sidebar state:', state);
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.ready = true;
    if (this.auth.isLoggedIn && this.auth.userRoles) {
      this.setRoleDashboard(this.auth.userRoles);
    }
    this.messenger.parcel.asObservable().subscribe(parcel => {
      switch (parcel.action) {
        case actions.roles:
          this.setRoleDashboard(parcel.data);
          break;
        case actions.amSelectCompany:
          if (this.auth.userRoles.accountmanager) {
            this.headerTitle = parcel.data.legalName;
          }
          break;
        case actions.login:
          this.setRoleDashboard(parcel.data);
          break;
        case actions.companyDetails:
          if (this.auth.userRoles.admin || this.auth.userRoles.employee) {
            this.headerTitle = parcel.data.legalName;
          }
          break;
      }
    })
  }

  public toggleLoginAs(role: string) {
    this.loginAs = role;
    const currentEmail = this.auth.user.email ?? '';
    localStorage.setItem(currentEmail, role);
    localStorage.setItem(rm_storageKeys.redirectTo, '');
    this.auth.reloadApp();
  }

  private setRoleDashboard(userRoles: AppRoles) {
    const currentEmail = this.auth.user.email ?? '';
    this.loginAs = localStorage.getItem(currentEmail) as string;
    this.isSuperAdmin = !!userRoles.superadmin;
    this.isAdmin = !!userRoles.admin;
    this.isAgent = !!userRoles.agent;
    this.isAccountManager = !!userRoles.accountmanager;
    this.isEmployee =  !!userRoles.employee;
    if (this.isSuperAdmin) {
      this.setSuperAdminLinks();
      this.headerTitle = 'RoadMate Super Admin';
      this.supportUrl = `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.support}`;
      return;
    }
    if (this.isAdmin && (this.loginAs === appRoles.admin || !this.loginAs)) {
      this.setAdminLinks();
      this.supportUrl = `/${appRoutes.admin}/${appRoutes.help}/${appRoutes.support}`
      return;
    }
    if (this.isAgent && (this.loginAs === appRoles.agent || !this.loginAs)) {
      this.setAgentLinks();
      this.supportUrl = `/${appRoutes.agent}/${appRoutes.help}/${appRoutes.support}`
      return;
    }
    if (this.isAccountManager && (this.loginAs === appRoles.accountManager || !this.loginAs)) {
      this.setAccountManagerLinks();
      this.supportUrl = `/${appRoutes.accountManager}/${appRoutes.help}/${appRoutes.support}`;
      return;
    }
    if (this.isEmployee && (this.loginAs === appRoles.employee || !this.loginAs)) {
      this.setEmployeeLinks();
      this.supportUrl = `/${appRoutes.employee}/${appRoutes.support}`
      return;
    }
  }

  private setSuperAdminLinks() {
    this.navLinks = superadminLinks;
  }

  private setAdminLinks() {
    const company = this.fs.currentCompany;
    if (!company) {
      return;
    }
    this.headerTitle = company.legalName;
    if (company.kycReview === Treezor.User.kycReview.VALIDATED) {
      if (company.orderCount === 0) {
        this.navLinks = loggedInNotConfigured;
      }
      const navLinks: any = [
        {
          title: true,
          name: 'Compte'
        },
        {
          name: 'Mon Profil',
          url: `${appRoutes.settings}/${appRoutes.profile}`,
          iconComponent: { name: 'cil-user', content: cilUser }
        },
        {
          name: 'Paramètres',
          url: `/admin/${appRoutes.settings}/${appRoutes.customizations}`,
          iconComponent: { name: 'cil-setting', content: cilSettings }
        },
        // ...adminLinks.filter(el => !el.children)
      ];
      if (this.isEmployee) {
        navLinks.push({
          title: true,
          name: 'Changer de statut'
        });
        // navLinks.push({
        //   name: '',
        //   divider: true,
        //   iconComponent: { name: '', content: [] }
        // });
        navLinks.push(
          {
          name: `Bénéficiaire`,
          variant: appRoutes.employee,
          iconComponent: { name: 'cil-transfer', content: cilTransfer }
        });
      }
      this.navLinks = [
        ...navLinks,
        {
          name: '',
          divider: true,
          iconComponent: { name: '', content: [] }
        },
        {
          name: 'Déconnexion',
          url: `/${appRoutes.logout}`,
          iconComponent: { name: 'cil-account-logout', content: cilAccountLogout },
        }
      ]      
    } else {
      this.navLinks = loggedInNotValidated;
    }
  }

  private setAgentLinks() {
    this.navLinks = agentLinks;
  }

  private setAccountManagerLinks() {
    this.navLinks = [
      {
        name: 'Mon Profil',
        url: `${appRoutes.settings}/${appRoutes.profile}`,
        iconComponent: { name: 'cil-user' }
      },
      ...amCompanyNotSelected
    ];
    if (this.isEmployee) {
      this.navLinks.push({
        name: `Passer en mode Bénéficiaire`,
        variant: appRoutes.employee,
        iconComponent: { name: 'cil-transfer' }
      });
    }
  }

  private setEmployeeLinks() {
    this.navLinks = [
      {
        title: true,
        name: 'Vos données',
      },
      {
        name: 'Mon Profil',
        url: `${appRoutes.settings}/${appRoutes.profile}`,
        iconComponent: { content: cilUser }
      },
      {
        title: true,
        name: 'Raccourcis',
      },
      ...employeeNavLinks
    ];
    if (this.isAdmin) {
      this.navLinks.push({
        title: true,
        name: 'Actuellement Bénéficiaire',
      });
      this.navLinks.push({
        name: `Passer en mode Admin`,
        variant: appRoutes.admin,
        iconComponent: { content: cilTransfer }
      });
    }
    if (this.fs.currentCompany) {
      this.headerTitle = this.fs.currentCompany.legalName;
    }
  }



  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
  }

}
