import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appRoles, AvailableIntegration, BridgeType,
  error_messages, fileStoringWorkflows, GroupedDDL, DropDownListOption, Treezor } from '@roadmate/roadmate-common';
import { FireStoreService, ModalService } from '../../../services';
import { FireFunctionsService } from '../../../services/fire.functions';
import { RMToasterService } from '../../../services/toaster.service';
import { appRoutes, modes } from '../../../static';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ProgressComponent, TextColorDirective } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RMMultiSelectModule } from '../../multi-select/multi-select.module';
import { ImageRenderPipe } from '../../pipes';
import { UploadInputComponent } from '../../upload-input/upload-input.component';
import { UploadTaskComponent } from '../../upload-input/upload-task/upload-task.component';
import { IconDirective } from '@coreui/icons-angular';
import { cilChevronRight } from '@coreui/icons';


@Component({
  selector: 'rm-add-users-info',
  templateUrl: './add-users-info.component.html',
  styleUrls: ['./add-users-info.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsComponent, NgClass, ProgressComponent, TextColorDirective, NgIf, NgFor, UploadTaskComponent,
    TranslateModule, FormsModule, RMMultiSelectModule, ImageRenderPipe, UploadInputComponent, IconDirective
  ]
})
export class AddUsersInfoComponent extends AbstractEntryComponent<any> implements OnInit {
  public loading = false;
  public file: File;
  public workflow = fileStoringWorkflows.createUsers;
  public products: GroupedDDL[];
  public agentRef: string;
  public companyRef: string;
  public integrations: AvailableIntegration[] = [];
  public appUsersFoundIntergration: Treezor.AppUser[];
  public allEligibleUsers: DropDownListOption[] = [];
  public appUsersToAdd: Treezor.AppUser[];
  public dispayUsersToAdd = false;
  private currentIntegrationRef: string;
  public icons = {
    cilChevronRight
  }
  override set data(value: GroupedDDL[]) {
    if (value) {
      this.products = value
    }
  }
  constructor(
    private ff: FireFunctionsService,
    private fs: FireStoreService,
    private toast: RMToasterService,
    private router: Router,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.agentRef = this.fs.currentAgent.ref ?? '';
    this.companyRef = this.fs.currentCompany.ref ?? '';
    if (!this.agentRef || !this.companyRef) {
      throw error_messages.PLEASE_SELECT_ONE_COMPANY;
    }
    this.getIntegrations();
  }

  private async getIntegrations() {
    const intergrations = await this.fs.getCompanyAvailableIntegrations(
      this.agentRef,
      this.companyRef
    );
    this.integrations = intergrations.filter(el => el.categories?.find(el => el.value === BridgeType.syncUsersOnce));
  }

  public async useIntegration(integration: AvailableIntegration) {
    if (!integration?.ref) {
      return;
    }
    this.currentIntegrationRef = integration.ref;
    this.loading = true;
    try {
      const response = await this.ff.startAddingUsersFromIntegration(
        this.agentRef,
        this.companyRef,
        integration.ref,
        this.products
      );
      if (response.message === error_messages.OK && Array.isArray(response.result) && response.result.length && response.result.every(el => el.email)) {
        this.appUsersFoundIntergration = response.result;
        this.appUsersToAdd = this.appUsersFoundIntergration.filter(el => true);
        this.allEligibleUsers = this.appUsersFoundIntergration.map(
          u => ({
            label: `${u.firstname} ${u.lastname} - ${u.email}`,
            value: u.email
          })
        );
        this.dispayUsersToAdd = true;
      } else {
        this.toast.showGenericError(response.message);
        return;
      }
      
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public updateSelectedAppUsers(selectedItems: DropDownListOption[]) {
    this.appUsersToAdd = this.appUsersFoundIntergration.filter(
      el => selectedItems.find(s => s.value === el.email)
    );
  }

  public async confirmAddUsers() {
    if (!this.appUsersToAdd.length || !this.currentIntegrationRef) {
      return;
    }
    this.loading = true;
    try {
      const response = await this.ff.startAddingUsersFromIntegration(
        this.agentRef,
        this.companyRef,
        this.currentIntegrationRef,
        this.products,
        this.appUsersToAdd
      );
      if (response.message === error_messages.OK) {
        await this.toast.showTranslatedSuccess('import_was_successfull');
        this.close(null);
        return;
      } else {
        this.toast.showGenericError(response.message);
        this.close(null);
        return;
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public async install() {
    if (this.fs.currentAppUser.role === appRoles.admin) {
      await this.router.navigateByUrl(`/${appRoutes.admin}/${appRoutes.integrations}`);
    } else if (this.fs.currentAppUser.role === appRoles.accountManager) {
      await this.router.navigateByUrl(`/${appRoutes.accountManager}/${appRoutes.integrations}`);
    }
    this.close(null);
  }

  public selectFile(file: FileList) {
    const selectedFile = file.item(0);
    if (!selectedFile) {
      return;
    }
    this.file = selectedFile;
    this.loading = true;
  }

  public downloadTemplate(type: string) {
    if (type === 'csv') {
      window.open('/assets/files/importer_templater.csv', '_blank');
    } else {
      window.open('/assets/files/importer_templater.xlsx', '_blank');
    }
  }

  public async onFileUploaded(response: {
    downloadURL: string;
    fileName: string;
  } | null) {
    // console.log(url);
    if (!response) {
      return;
    }
    const res = await this.ff.startAddingUsers(this.agentRef, this.companyRef, response.fileName, this.products);
    if (res.message === error_messages.OK) {
      this.toast.showToast({
        title: 'Notification',
        message: `Les utilisateurs ajoutés vont recevoir un email pour activer leur compte`,
        isVisible: true,
        mode: modes.success,
        duration: 5000
      });
    } else {
      this.toast.showGenericError();
    }
    this.close(null);
  }
}
