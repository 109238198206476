import { Component, OnInit } from '@angular/core';
import { FormDefinition, Group, ObjectDefList } from '@roadmate/roadmate-common';
import { FireStoreService } from '../../../services/fire.store';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RMToasterService } from '../../../services/toaster.service';
import { modes } from '../../../static';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';
import { NgClass, NgIf } from '@angular/common';
import { ProgressComponent, TextColorDirective } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from '../../../services';



@Component({
  selector: 'rm-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsComponent, NgClass, ProgressComponent, TextColorDirective, NgIf, TranslateModule
  ]
})
export class AddGroupComponent extends AbstractEntryComponent<any> implements OnInit {
  public subtitle = 'add_new_group_subtitle';
  public groupDefinition: FormDefinition;
  public loading = false;
  public actionlbl: string;
  public mode:string;
  constructor(
    private fs: FireStoreService,
    private toast: RMToasterService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.getGroupDefinition();
  }

  public async onSaveEmitted(group: Group) {
    this.loading = true;
    try {
      this.toast.showToast({
        title: 'Parfait',
        message: 'Ce groupe a bien été créé',
        isVisible: true,
        mode: modes.success,
        duration: 5000
      });
      this.close(group);
    } catch (e) {
      console.error('Error while saving a group', e);
      this.toast.showToast({
        title: 'Oops',
        message: 'Ce groupe n\'a pas pu être créé',
        isVisible: true,
        mode: modes.danger,
        duration: 5000
      });
    } finally {
      this.loading = false;
    }
  }

  public async getGroupDefinition() {
    const list = await this.fs.getObjectsList();
    this.groupDefinition = list[ObjectDefList.groups];
  }

}
