<h4>
  {{'confirm_budget_adjustment' | translate}}
</h4>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'from_users_card' | translate}}</label>
      <div class="form-control" disabled="">
        {{email}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'transfer_to' | translate}}</label>
      <div class="form-control" disabled="">
        {{(transfer.to?.eventName ?? '') | translate}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'adjustment_amount' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.amount| rm_currency}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'adjustement_reference' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.label}}
      </div>
    </div>
  </div>
</div>


