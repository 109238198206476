import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {RMToasterComponent} from './modules/rm-toaster/rm-toaster.component';
import { FireAuthService } from './services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, RMToasterComponent]
})
export class AppComponent implements OnInit {
  title = 'RoadMate - Gestion de la mobilité';
  public isLogged = false;
  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private translate: TranslateService,
    private fa: FireAuthService
  ) {
    this.titleService.setTitle(this.title);
    // iconSet singleton
    this.iconSetService.icons = { ...iconSubset };
    this.translate.setDefaultLang('fr');
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    const customCss = localStorage.getItem('agentCssClass');
    const body = document.body;
    if(customCss) {
      if (!body.classList.contains(customCss)) {
        body.classList.add(customCss);
      }
    } else {
      body.classList.add('roadmate');
    }
    this.isLogged = this.fa.isLoggedIn;
    document.addEventListener("visibilitychange", async () => {
      if (document.hidden) {
        localStorage.setItem('lastActive', new Date().getTime().toString());
      }
      else {
        setTimeout(() => {
          const lastActive = localStorage.getItem('lastActive');
          if (lastActive) {
            const diff = new Date().getTime() - parseInt(lastActive);
            if (diff > 60 * 15 * 1000) {
              this.fa.logout();
            }
          }
        }, 1000)
      }
    });
  }
}
