<c-progress variant="striped" [value]="100" animated class="mb-3" color="primary" *ngIf="!treezorUsers">
  {{'loading' | translate}}
</c-progress>
<p-accordion [activeIndex]="-1" *ngIf="treezorUsers?.length">
  <p-accordionTab [header]="item.email" *ngFor="let item of treezorUsers">
    <c-row>
      <c-col sm="12" md="6">
        <rm-reactive-forms
          [objectDefinition]="treezorDef"
          [objectToUpdate]="item"
          [displayOnly]="true"
        >
        </rm-reactive-forms>
      </c-col>
      <c-col sm="12" md="6">
        <h3>Documents associés</h3>
        <c-row *ngIf="treezorUploadedDocuments?.length">
          <c-col sm="6" md="4" *ngFor="let doc of treezorUploadedDocuments | rm_filter: 'userId':item.userId">
            <c-card>
              <img cCardImg="top" src="./assets/svg/pdf.svg">
              <c-card-body>
                <p cCardText>
                  <a href="{{doc.url}}" target="_blank">{{doc.name | truncate:20}}</a>
                </p>
              </c-card-body>
            </c-card>
          </c-col>
        </c-row>
      </c-col>
    </c-row>
  </p-accordionTab>
</p-accordion>
<h3 *ngIf="treezorUsers?.length === 0">
  Aucun utilisateur BE n'a été trouvé
</h3>