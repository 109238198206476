<div class="nav-tabs-boxed">
  <c-tabs [activeItemKey]="currentTab" (activeItemKeyChange)="setTab($any($event))">
    <c-tabs-list variant="underline-border">
      <button cTab [itemKey]="0">{{ 'personal_data_tab' | translate}}</button>
      <button cTab [itemKey]="1">{{ 'user_card_tab' | translate}}</button>
      <button cTab [itemKey]="2">{{ 'user_access_level' | translate}}</button>
      <button cTab [itemKey]="3">{{ 'user_group_tab' | translate}}</button>
      <button cTab [itemKey]="4" *ngIf="isSuperAdmin">Update Treezor user</button>
      <button cTab [itemKey]="5" *ngIf="isEmployee && (isSuperAdmin || isAdminOrAccountManager)">
        <span *ngIf="isSuperAdmin">
          Transactions Carte
        </span>
        <span *ngIf="isSuperAdmin">
          {{'refunds_and_transactions'|translate}}
        </span>
      </button>
      <button cTab [itemKey]="6" *ngIf="isSuperAdmin">Demandes de remboursement</button>
    </c-tabs-list>
    <c-tabs-content>
      <c-tab-panel class="p-3" [itemKey]="0" [transition]="false">
        <c-progress variant="striped" *ngIf="!userSituationRequest" [value]="100" animated class="mb-3" color="primary">{{'loading' | translate}}</c-progress>
        <ul cListGroup  *ngIf="!loading && isInvitedButNotSubscribed" class="push-down">
          <li cListGroupItem>
            <c-row>
              <c-col sm="12" md="8">
                {{'this_user_is_invited_but_not_subscribed' | translate}}
              </c-col>
              <c-col sm="12" md="4">
                <button class="btn btn-primary" type="button" (click)="deleteUser()">
                  {{'delete_user' | translate}}
                </button>
              </c-col>
            </c-row>
          </li>
          <li cListGroupItem>
            <c-row>
              <c-col sm="12">
                <svg [cIcon]="icons.cilWarning" color="danger" size="sm" title=""></svg>
                {{'warning_if_deleted_user_will_not_be_able_to_subscribe' | translate}}
              </c-col>
            </c-row>
          </li>
          <li cListGroupItem>
            <c-row>
              <c-col sm="12" md="8">
                {{'you_can_send_a_new_invitation'|translate}}
              </c-col>
              <c-col sm="12" md="4">
                <button class="btn btn-primary" type="button" (click)="sendNewInvitation()">
                  {{'send_invitation' | translate}}
                </button>
              </c-col>
            </c-row>
          </li>
        </ul>
        
        <rm-reactive-forms 
          *ngIf="objectDef && targetObject && currentTab === 0" 
          [objectDefinition]="objectDef"
          [objectToUpdate]="targetObject" 
          (saveEmitter)="sendObjectToSender($event)"
        >
        </rm-reactive-forms>
      </c-tab-panel>
      <c-tab-panel class="p-3" [itemKey]="1" [transition]="false">
        @if(!userSituationRequest) {
          <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary">
            {{'loading' | translate}}
          </c-progress>
        } @else {
          <rm-user-card 
            [targetObject]="targetObject" 
            [isInvitedButNotSubscribed]="isInvitedButNotSubscribed"
            [hasNoCard]="hasNoCard"
            [card]="card"
            (actionEmitter)="updateUserCard($event)"
          ></rm-user-card>
        }
      </c-tab-panel>
      <c-tab-panel class="p-3" [itemKey]="2" [transition]="false">
        @if(!userSituationRequest) {
          <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary"> </c-progress>
        } @else {
          <rm-user-access-level
            [targetObject]="targetObject"
            [userACL]="userACL"
            [suspended]="suspended"
            (actionEmitter)="updateUserACL($event)"
          ></rm-user-access-level>
        }
      </c-tab-panel>
      <c-tab-panel class="p-3" [itemKey]="3" [transition]="false">
        @if(!userSituationRequest) {
          <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary"> </c-progress>
        } @else {
          <rm-user-groups-budget
            [targetObject]="targetObject"
            [expenseLines]="expenseLines"
          ></rm-user-groups-budget>
        }
      </c-tab-panel>
      <c-tab-panel class="p-3" *ngIf="isSuperAdmin" [itemKey]="4" [transition]="false">
        <h5>
          {{'treezor_details' | translate}}
        </h5>
        <button class="btn btn-primary" [disabled]="loading" type="button" (click)="syncTreezorUser()">
          {{'sync_user_treezor' | translate}}
        </button>
        <c-progress variant="striped" *ngIf="loading" [value]="100" color="primary" animated class="mb-3"/>
        <rm-reactive-forms *ngIf="treezorUserDef && treezorUser" [objectDefinition]="treezorUserDef"
          [objectToUpdate]="treezorUser" [displayOnly]="false" (saveEmitter)="updateTreezorUser($event)">
        </rm-reactive-forms>
        <ng-container *ngIf="!loading && (!treezorUser || !treezorUser.userId)">
          <p>
            {{'this_user_doent_exist_yet' | translate}}
          </p>
          <button class="btn btn-primary" type="button" [disabled]="loading" (click)="createTreezorUser()">
            {{'add_user_treezor' | translate}}
          </button>
        </ng-container>
      </c-tab-panel>
      <c-tab-panel class="p-3" [itemKey]="5" *ngIf="isEmployee && (isSuperAdmin || isAdminOrAccountManager)" [transition]="false">
        <rm-user-refunds-transactions
          *ngIf="isAdminOrAccountManager"
          [appUser]="targetObject"
        >
        </rm-user-refunds-transactions>
        <rm-transactions-viewer
          *ngIf="isSuperAdmin && targetObject"
          [agentRef]="targetObject.agentRef"
          [companyRef]="targetObject.companyRef"
          [email]="targetObject.email"
        ></rm-transactions-viewer>
      </c-tab-panel>
      <c-tab-panel class="p-3" *ngIf="isSuperAdmin" [itemKey]="6" [transition]="false">
        <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary" *ngIf="loading || !requests"></c-progress>
        <ng2-smart-table
            *ngIf="requests?.length && requestsSettings"
            [settings]="requestsSettings"
            [source]="requests"
          >
        </ng2-smart-table>
      </c-tab-panel>
    </c-tabs-content>
  </c-tabs>
</div>