<h5>{{'import_delete_users_subtitle' | translate}}</h5>
<div class="row">
  <div class="col-12">
    <div [innerHTML]="'enter_list_of_emails_to_delete' | translate"></div>
    <textarea rows="10" style="width: 100%; margin-top: 20px;" [(ngModel)]="emails">
    </textarea>
  </div>
  <div class="col-12 detached">
    <c-form-check sizing="xl" switch>
      <input cFormCheckInput type="checkbox" [(ngModel)]="doitNow" [checked]="doitNow" (click)="updateToggles(1)"/>
      <label cFormCheckLabel>{{'delete_immediately' | translate}}</label>
    </c-form-check>
    <!-- <div class="rm-row checkbox">
      <label class="c-switch c-switch-primary">
        <input
          [(ngModel)]="doitNow"
          class="c-switch-input"
          type="checkbox"
          [checked]="doitNow"
          (click)="updateToggles(1)"
        > <span class="c-switch-slider"></span>
      </label>
      <label class="form-check-label" style="margin-left:15px">
        {{'delete_immediately' | translate}}
      </label>
    </div> -->
  </div>
  <div class="col-12 detached">
    <c-form-check sizing="xl" switch>
      <input cFormCheckInput type="checkbox" [(ngModel)]="schedule" [checked]="schedule" (click)="updateToggles(2)"/>
      <label cFormCheckLabel>{{'plan_deletion_for' | translate}}</label>
    </c-form-check>
    <!-- <div class="rm-row checkbox">
      <label class="c-switch c-switch-primary">
        <input
          [(ngModel)]="schedule"
          class="c-switch-input"
          type="checkbox"
          [checked]="schedule"
          (click)="updateToggles(2)"
        > <span class="c-switch-slider"></span>
      </label>
      <label class="form-check-label" style="margin-left:15px">
        {{'plan_deletion_for' | translate}}
      </label>
    </div> -->
    
    <!-- <p-calendar
      *ngIf="schedule"
      [(ngModel)]="startDate"
      [locale]="fr"
      [readonlyInput]="true"
      [numberOfMonths]="1"
      [minDate]="minStartDate"
      dateFormat="dd/mm/yy"
    ></p-calendar> -->
  </div>
  <div class="col-6 detached" *ngIf="schedule">
    <c-date-picker
    [(ngModel)]="startDate"
    locale="fr" 
    [showAdjacentDays]="false"
    firstDayOfWeek="1"
    [minDate]="minStartDate"
    />
  </div>
  <div class="col-12 detached" [hidden]="!schedule">
    <c-form-check sizing="xl" switch>
      <input cFormCheckInput type="checkbox" [(ngModel)]="notifyUsers" [checked]="notifyUsers" (click)="updateToggles(2)"/>
      <label cFormCheckLabel>{{'notify_users_upcoming_deletion' | translate}}</label>
    </c-form-check>
  </div>
</div>
<div class="row detached">
  <div class="col-6">
    <button class="btn btn-primary" type="button" (click)="submit()" [disabled]="!emails.length">
      {{'validate' | translate}}
    </button>
  </div>
</div>
