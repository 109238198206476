<rm-reactive-forms
  *ngIf="groupDefinition"
  [ngClass]="{'rm-hide': loading}"
  [objectDefinition]="groupDefinition"
  (saveEmitter)="onSaveEmitted($event)"
>
</rm-reactive-forms>
 <c-progress variant="striped" *ngIf="loading" [value]="100" animated class="mb-3"></c-progress>
<div class="modal-footer" >
  <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="close(false)">
    {{'cancel' | translate}}
  </button>
  <button class="btn btn-{{mode}}" type="button" (click)="close(true)">
    <span *ngIf="actionlbl">
      {{actionlbl | translate}}
    </span>
    <span *ngIf="!actionlbl">
      {{ 'ok' | translate}}
    </span>
  </button>
</div>
