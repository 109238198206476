import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { FireStoreService, ModalService, RMToasterService } from '../../../services';
import { Tabs2Module } from '@coreui/angular';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';
import { Kyb, RoadMateTimeZone } from '@roadmate/roadmate-common';
import moment from 'moment';
import {ViewKybBeneficiariesComponent} from './view-kyb-beneficiaries/view-kyb-beneficiaries.component';
import {ViewUploadedDocumentsComponent} from './view-uploaded-documents/view-uploaded-documents.component';

@Component({
  selector: 'rm-edit-kyx',
  templateUrl: './edit-kyx.component.html',
  styleUrls: ['./edit-kyx.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, NgFor, NgIf,
    Tabs2Module, ReactiveFormsComponent,
    ViewKybBeneficiariesComponent, ViewUploadedDocumentsComponent
  ]
})
export class EditKyxComponent extends AbstractEntryComponent<boolean> implements OnInit {
  @Input()
  kyb: Kyb;
  @Input()
  readonly: boolean;
  override set data(value: any) {
    if (!value) {
      return;
    }
    this.kyb = value.kyb;
    this.readonly = value.readonly 
  }
  ready = false;
  loading = false;
  currentTab = 'kyb';
  public kybFormDefinition: any;
  constructor(
    modalService: ModalService,
    private fs: FireStoreService,
    private toast: RMToasterService
  ) {
    super(modalService);
  }

  public ngOnInit(): void {
    this.setSetting();
  }

  setTab(tab: string) {
    this.currentTab = tab;
    console.log('currentTab', this.currentTab);
  }

  private setSetting() {
    this.kybFormDefinition = this.fs.getObjectDefinitionByRef('kyb');
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.ready = true;
  }

  public async sendObjectToSender(kyb: Kyb) {
    try {
      this.loading = true;
      if (
        !kyb.business_beneficiaries?.length &&
        !kyb.physical_beneficiaries?.length &&
        !kyb.physical_rl?.length &&
        !kyb.business_rl?.length &&
        !kyb.kbis?.length
      ) {
        this.toast.showGenericError('you_need_to_declare_atleast_one_beneficiary');
        return;
      }
      // const kybToUpdate = this.listOfgKybsToFill.find(el => el.ref === kyb.ref);
      if (!kyb.ref) {
        this.toast.showGenericError( 'kyb_not_found');
        return;
      }
      const uptodatekyb = kyb;
      uptodatekyb.submittedAt = uptodatekyb.submittedAt ? uptodatekyb.submittedAt : moment().local().format(RoadMateTimeZone.dateTimeFormat);
      uptodatekyb.updatedAt = moment().local().format(RoadMateTimeZone.dateTimeFormat);
      uptodatekyb.updatedBy = this.fs.currentAppUser.email;
      if (
        uptodatekyb.physical_beneficiaries?.find(el => el.email === this.fs.currentAppUser.email)
        || uptodatekyb.physical_rl?.find(el => el.email === this.fs.currentAppUser.email)
      ) {
        this.toast.showGenericError(`Email doublon: Veuillez saisir un email différent de celui de (${this.fs.currentAppUser.email}).
        Les emails que vous déclarez pour les bénéficiaires effectifs ou représentants légaux ne peuvent être utilisés par
        les utilisateurs de l'application ou le back office`);
        return;
      }
      if (uptodatekyb.physical_beneficiaries?.length
          && uptodatekyb.physical_rl?.length
          && uptodatekyb.physical_beneficiaries
          .map(el => el.email)
          .some(email => uptodatekyb.physical_rl?.map(item => item.email).includes(email))
      ) {
        this.toast.showGenericError(`Email doublon: Veuillez saisir un email différent pour chaque personne que vous déclarez.
        PS: Si le bénéficiaire effectif et le représentant légal sont représentés par la même personne alors
        il faut la déclarer une seule fois en tant que bénéficiaire effectif en précisant son rôle dans l'entreprise.`);
        return;
      }
      uptodatekyb.source = 'user';
      if (!uptodatekyb.ref) {
        this.toast.showGenericError('Il manque la ref de ce kyb pour le sauvegarder');
        return;
      }
      await this.fs.saveKyb(uptodatekyb, uptodatekyb.ref);
      this.toast.showSuccessToast('KYB saved successfully');
    } catch (e) {
      this.toast.showGenericError('Les informations saisies n\'ont pas pu être sauvegardées. Veuillez réessayer plus tard.');
      console.error('Could not save Kyc Details', e);
    } finally {
      this.loading = false;
    }
  }

  
}
