import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListGroupModule, ProgressComponent, TextColorDirective, RowComponent, ColComponent } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CardSituation, Treezor } from '@roadmate/roadmate-common';
import { FireAuthService } from '../../../services';
import { AnimatedSVGModule } from '../../animated-svg-components/animated-svg.module';

export enum UserCardComponentFeatures {
  DELETE_USER = 'DELETE_USER',
  INVITE_USER  = 'INVITE_USER',
  ORDER_NEW_CARD = 'ORDER_NEW_CARD',
  LOCK_CARD = 'LOCK_CARD',
  UNLOCK_CARD = 'UNLOCK_CARD'
}


@Component({
  selector: 'rm-user-card',
  templateUrl: './user-card.component.html',
  standalone: true,
  imports: [
    TranslateModule, NgIf, RowComponent, ColComponent, ListGroupModule, 
    AnimatedSVGModule, ProgressComponent, TextColorDirective, NgClass
  ]
})
export class UserCardComponent implements OnInit {
  public loadingCards = true;
  @Input({
    required: true
  })
  public isInvitedButNotSubscribed = false;
  @Input({
    required: true
  })
  public hasNoCard = true;
  @Output()
  public actionEmitter = new EventEmitter<UserCardComponentFeatures>();
  @Input({required: true})
  targetObject: Treezor.AppUser;
  @Input({required: true})
  public card: CardSituation
  public isSuperAdmin = false;
  constructor(
    public auth: FireAuthService
  ) {
    this.isSuperAdmin = this.auth.isSuperAdmin;
  }

  ngOnInit(): void { }

  public lockCard(lock: Boolean) {
    if (lock) {
      this.actionEmitter.emit(UserCardComponentFeatures.LOCK_CARD);
    } else {
      this.actionEmitter.emit(UserCardComponentFeatures.UNLOCK_CARD);
    }
  }

  public deleteUser() {
    this.actionEmitter.emit(UserCardComponentFeatures.DELETE_USER);
  }

  public sendNewInvitation() {
    this.actionEmitter.emit(UserCardComponentFeatures.INVITE_USER);
  }

  public orderNewCard() {
    this.actionEmitter.emit(UserCardComponentFeatures.ORDER_NEW_CARD);
  }

  
}
