import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions
} from '@angular/router';
import { FirestoreModule, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth, AuthModule } from '@angular/fire/auth';
import { provideFunctions, getFunctions, FunctionsModule } from '@angular/fire/functions';
import { provideStorage, getStorage, StorageModule } from '@angular/fire/storage'
import { DropdownModule, SidebarModule } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { routes } from './app.routes';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';


// Function for loading translation files
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// const firebaseApp = initializeApp(environment.firebase);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'ignore'
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled'
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      // withHashLocation()
    ),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    })),
    importProvidersFrom(SidebarModule, DropdownModule),
    IconSetService,
    TranslateService,
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'fr-FR'},    
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions(
      initializeApp(environment.firebase),
      'europe-west1'
    )),
    importProvidersFrom(AuthModule),
    importProvidersFrom(FirestoreModule),
    importProvidersFrom(FunctionsModule),
    importProvidersFrom(StorageModule),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    { provide: 'FIREBASE_OPTIONS', useValue: environment.firebase },    
  ]
};
