<c-tabs [activeItemKey]="allTabls.settings" (activeItemKeyChange)="setTab($any($event))">
  <c-tabs-list variant="underline-border">
    <button cTab [itemKey]="allTabls.settings">{{allTabls.settings | translate}}</button>
    <button cTab [itemKey]="allTabls.profile">{{allTabls.profile | translate}}</button>
    <button cTab [itemKey]="allTabls.invoicesettings">{{allTabls.invoicesettings | translate}}</button>
    <button cTab [itemKey]="allTabls.kyb">{{allTabls.kyb | translate}}</button>
  </c-tabs-list>
  <c-tabs-content>
    <c-tab-panel class="p-3" [itemKey]="allTabls.settings" [transition]="false">
      <h4 class="push-down">{{'applied_settings' | translate}} {{company.legalName}}</h4>
      <div *ngIf="settingViewing && !currentSetting; else updateSetting">
        <ng2-smart-table
          *ngIf="tableSettings && settings"
          [settings]="tableSettings"
          [source]="settings"
          (userRowSelect)="selecteSetting($event)"
        >
        </ng2-smart-table>
      </div>
      <ng-template #updateSetting>
        <rm-update-setting
          [mode]="'company'"
          [companyRef]="company.ref ?? ''"
          [agentRef]="company.agentRef"
          [setting]="$any(currentSetting)"
          (saveSettingEmitter)="doneEditing($event)"
        ></rm-update-setting>
      </ng-template>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="allTabls.profile" [transition]="false">
      <h4>{{'company_products' | translate}} {{company.legalName}}</h4>
      <div class="rm-row">
        <div class="col-3" *ngFor="let product of products; let i = index">
          <div class="card">
            <img [src]="product.img" class="card-img-top">
            <div class="card-body">
              <h5 
                class="card-title"
              >
                {{('PRODUCT_'+product.code) | translate}}
                <i class="cil-info" class="green cil-check-alt" *ngIf="hasOrderThisProduct(product)"></i>
              </h5>
              <p class="card-text">
                {{product.description}}
              </p>
              <button
                class="btn btn-primary"
                type="button"
                (click)="addProduct(product)"
                [disabled]="loading === i"
                *ngIf="!hasOrderThisProduct(product);"
              >
                <span *ngIf="intent !== product.code">{{'add' | translate}}</span>
                <span *ngIf="intent === product.code">
                  Vous êtes sûr ?
                </span>
                <span *ngIf="loading === i" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
              </button>
              <button
                  class="btn btn-primary"
                  type="button"
                  (click)="openIban(product.url)"
                  *ngIf="hasOrderThisProduct(product) && product.url"
                >
                {{'download_iban' | translate}}
                <i class="cil-external-link"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="allTabls.invoicesettings" [transition]="false">
      <c-progress variant="striped" *ngIf="loading === 3" [value]="100" color="primary" animated class="mb-3"/>
      <div *ngIf="loading !== 3">
        <h5>{{'invoice_management' | translate}}</h5>
        <div class="detached" *ngIf="invoiceSettingValues?.length">
          <rm-update-setting
            *ngFor="let sett of invoiceSettingValues"
            [mode]="'company'"
            [companyRef]="company.ref ?? ''"
            [agentRef]="company.agentRef"
            [setting]="sett"
            [standalone]="false"
          ></rm-update-setting>
        </div>
      </div>
    </c-tab-panel>
    <c-tab-panel class="p-3" [itemKey]="allTabls.kyb" [transition]="false">
      <div class="push-down detached">
        <h5>{{'compnay_kyb' | translate}}</h5>
        <div class="row detached" >
          <div class="col-6" *ngIf="!kyb">
            <button class="btn btn-primary" type="button" (click)="createKyb()" [disabled]="loadingKyb">
              <span *ngIf="loadingKyb" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
              {{'Créer le kyb' | translate}}
            </button>
            <c-progress variant="striped" *ngIf="loadingKyb" [value]="100" color="primary" animated class="mb-3"/>
          </div>
          <div class="col-6" *ngIf="kyb">
            <rm-reactive-forms
              *ngIf="kybDef && kyb"
              [objectDefinition]="kybDef"
              [objectToUpdate]="kyb"
              [displayOnly]="true"
            >
            </rm-reactive-forms>
          </div>
        </div>
        <c-progress variant="striped" *ngIf="loading === 2" [value]="100" color="primary" animated class="mb-3"/>
      </div>
    </c-tab-panel>
  </c-tabs-content>
</c-tabs>






