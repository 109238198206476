<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed border-end"
  colorScheme="light"
  id="sidebar1"
  visible
  [unfoldable]="true"
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand 
      [routerLink]="['/']"
      [brandFull]="selectedLogos.rectangle"
      [brandNarrow]="selectedLogos.square"
    >
    </c-sidebar-brand>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" [compact]="false" />
  </ng-scrollbar>
  @if (!sidebar1.narrow) {
    <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex">
      <!-- <div class="logout-action rm-clickable" (click)="logout()">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg> 
        <label class="label-action">
          {{'logout'|translate}}
        </label>
      </div> 
      <div class="logout-icon rm-clickable">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg>
      </div> -->
      <div class="logout-action rm-clickable" (click)="logout()">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg> 
        <label class="label-action rm-clickable">
          {{'logout'|translate}}
        </label>
      </div> 
      <!-- <div class="logout-icon rm-clickable">
        <svg [cIcon]="icons.cilAccountLogout" size="lg" title="Déconnexion"></svg>
      </div> -->
    </c-sidebar-footer>
  }
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-default-header
    [cShadowOnScroll]="'sm'"
    class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
    position="sticky"
    sidebarId="sidebar1"
  />
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto px-4">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>
<ng-container *ngIf="parcel | async as parcelObject">
  <rm-modal
    *ngIf="parcelObject.isOpen"
    class="modal"
    [modalTitle]="parcelObject.modalTitle ?? ''"
    [modalId]="parcelObject.id ?? ''"
    [mode]="parcelObject.mode ?? 'primary'"
    [actionlbl]="parcelObject.actionlbl ?? ''"
    [componentName]="parcelObject.component"
    [dismissFooter]="!!parcelObject.dismissFooter"
    [data]="parcelObject.data"
    [headerSuffix]="parcelObject.headerSuffix ?? ''"
    [size]="parcelObject.size ?? 'regular'"
  >
  </rm-modal>
</ng-container>
