<div class="rm-column" *ngIf="description">
  <p [innerHTML]="description | translate"></p>
</div>
<c-progress variant="striped" *ngIf="!objectDef" [value]="100" color="primary" animated class="mb-3"/>
<rm-reactive-forms
  *ngIf="objectDef"
  [objectDefinition]="objectDef"
  [objectToUpdate]="targetObject"
  [displayOnly]="isReadOnly"
  (saveEmitter)="sendObjectToSender($event)"
  [placeSaveToRight]="true"
  [fileStoringWorkflow]="fileStoringWorkflow"
>
</rm-reactive-forms>
<div class="rm-row" *ngIf="btns && btns.length">
  <button
    *ngFor="let btn of btns"
    class="btn btn-primary"
    type="button"
    (click)="btnClick(btn)"
  >
    {{btn.label | translate}}
  </button>
</div>
