<c-progress variant="striped" *ngIf="loading" [value]="100" color="primary" animated class="mb-3"/>
@if(ordersLoaded && orders.length === 0) {
  <p>
    {{'you_have_not_created_orders' | translate}}
  </p>
}
@else {
  <c-row class="detached">
    <c-col sm="12" md="8">
      <div class="rm-column" style="height: auto; width: 100%">
        <p *ngIf="orders && targetObject && (!targetObject.userGroupIds || !targetObject.userGroupIds.length)">
          {{'no_groups_associated_to_user' | translate}}
        </p>
        <p *ngIf="savedSuccess">
          {{'group_assiciated' | translate}}
        </p>
        <rm-grouped-multi-select [groups]="groups"
          [selectedItems]="targetObject.userGroupIds ? targetObject.userGroupIds : []"
          [placeholder]="'group_assiciated'" (changeEmitter)="updateUserGroups($event)">
        </rm-grouped-multi-select>
        <button class="btn btn-primary detached" type="button" (click)="saveGroups()"
          [disabled]="!groupSelectionDirty || savingLoader">
          {{'save' | translate}}
        </button>
      </div>
    </c-col>
    <c-col sm="12" md="6"></c-col>
  </c-row>
  <c-row>
    <c-col sm="12">
      <h5 class="detached">
        {{'expense_lines' | translate}}
      </h5>
      <p *ngIf="expenseLines && expenseLines.length === 0">
        {{ 'no_expense_lines_so_far' | translate}}
      </p>
      <ul *ngIf="expenseLines && expenseLines.length" cListGroup>
        <li *ngFor="let expense of expenseLines" cListGroupItem>
          <c-row>
            <c-col sm="12" md="9">
              <div class="small mb-1">
                {{expense.productName | translate}}
              </div>
              <c-progress [value]="100 * expense.remainingBalance/expense.initialBalance" [color]="expense.status | expenseline_status_color" class="mb-3">
                {{expense.amountSpent}} € / {{expense.initialBalance}} €
              </c-progress>
              <div>
                <rm-expenseline-status [status]="expense.status"></rm-expenseline-status>
              </div>
            </c-col>
            <c-col sm="12" md="3" class="right align-content-center">
              <button class="btn btn-danger" type="button" (click)="suspendExpenseLine(expense)"
                *ngIf="expense.status === 'Active' || expense.status === 'Available' ">
                {{'suspend' | translate}}
              </button>
              <button class="btn btn-success" type="button" (click)="suspendExpenseLine(expense, false)"
                *ngIf="expense.status === 'Suspended'">
                {{'activate' | translate}}
              </button>
            </c-col>
          </c-row>
        </li>
      </ul>
    </c-col>
  </c-row>
}
