import { Component } from '@angular/core';
import { FooterComponent } from '@coreui/angular';
import { RoadMateEnvironmentNameSpace } from '@roadmate/roadmate-common';

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
    standalone: true,
    imports: [
      FooterComponent
    ]
})
export class DefaultFooterComponent extends FooterComponent {
  public year = new Date().getFullYear();
  public dashVersion = RoadMateEnvironmentNameSpace.dashVersion;
  constructor() {
    super();
  }
}
