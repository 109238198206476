import { Component, OnInit } from '@angular/core';
import { appRoles, error_messages, TransportCategory, TransportWhiteList } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, ModalService, RMToasterService } from '../../../services';
import { RNode } from '../../../static/rnode';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateModule } from '@ngx-translate/core';
import { TreeModule } from 'primeng/tree';
import { NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@coreui/angular';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'rm-display-whitelist',
  templateUrl: './display-whitelist.component.html',
  styleUrls: ['./display-whitelist.component.scss'],
  standalone: true,
  imports: [
    TranslateModule, TreeModule, NgClass, NgIf, TooltipDirective, FormsModule
  ]
})
export class DisplayWhiteListTreeComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public whiteList: TransportWhiteList;
  public transports: TransportCategory[];
  public treeNode: RNode<any>[];
  public loading = false;
  public isSuperAdmin = false;
  override set data(value: any) {
    if (!value || !value.whiteList?.treeNode.length) {
      return;
    } else {
      this.whiteList = value.whiteList;
      this.transports = value.whiteList.treeNode;
      this.getAllCategories();
    }
  }
  constructor (
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.fs.currentAppUser.role === appRoles.superadmin;
  }

  private async getAllCategories() {
    if (!this.transports.length) {
      return;
    }
    this.treeNode = this.transports.map(el => {
      return {
        label: el.name,
        data: el.ref,
        selectable: true,
        type: 'category',
        isSelected: el.isAuthorized,
        key: el.icon && el.icon.length ? el.icon[0].url : '',
        children: this.getChildren(el)
      }
    });
  }

  public async propagateCategory(treeNode: RNode<any>) {
    this.loading = true;
    try {
      const category = this.whiteList.treeNode.find(el => el.ref === treeNode.data);
      if (!category || !this.whiteList.ref) {
        this.toast.showGenericError();
        return;
      }
      console.log(category);
      const res = await this.ff.propagateTransportCategory(this.whiteList.ref, this.whiteList.treeNode);
      if (res.message === error_messages.OK) {
        this.toast.showSuccessToast(`Le traitement s'est bien déroulé. ${res.result} listes ont été modifiées`);
      } else {
        this.toast.showGenericError(res.message);
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public getChildren(transport: TransportCategory) {
    const nodes: RNode<any>[] = transport.merchants?.length ?
    transport.merchants.map(merchant => {
      return {
        label: merchant.label,
        data: merchant.value,
        selectable: true,
        type: 'merchant',
        key: merchant.img ?? '',
        isSelected: !!merchant.isSelected,
      }
    })
    : [];
    if (transport.subCategories?.length) {
      transport.subCategories.forEach(
        sub => {
          nodes.push({
            label: sub.name,
            data: sub.ref,
            selectable: true,
            type: 'subCategory',
            key: sub.icon && sub.icon.length ? sub.icon[0].url : '',
            isSelected: sub.isAuthorized,
            children: this.getChildren(sub)
          });
        }
      )
    }
    return nodes;
  }

  public expandAll(){
    this.treeNode.forEach( node => {
        this.expandRecursive(node, true);
    } );
  }

  public collapseAll(){
    this.treeNode.forEach( node => {
        this.expandRecursive(node, false);
    } );
  }

  private expandRecursive(node:RNode<any>, isExpand:boolean){
    node.expanded = isExpand;
    if (node.children){
      node.children.forEach( childNode => {
        this.expandRecursive(childNode, isExpand);
      } );
    }
  }

  public async save () {
    this.loading = true;
    try {
      if (!this.whiteList.ref) {
        this.loading = false;
        return;
      }
      await this.fs.updateCompanyWhiteList(this.whiteList.ref, {customRules: this.whiteList.customRules ?? ''});
    } catch (e) {
      console.error('Could not change white list', e);
    } finally {
      this.loading = false;
      this.close(false);
    }
  }
}
