import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { 
  DropDownListOption, error_messages, FormDefinition, NotificationScope,
  ObjectDefList, paymentFrequency, RoadMateOrders, RoadMateProduct, roadmateProducts,
  SendableNotification, Setting, SettingNames, Treezor, usualPaymentDay,
  LineItems, rmSleep, RMInvoice, Kyb, kybStatus,
  CompanySettings
} from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService, ModalService, RMToasterService } from '../../../services';
import { getTableSettings } from '../../../utils';
import { AbstractEntryComponent } from '../abstract.entry-component';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Ng2SmartTableComponent } from '../../migration/tables/ng2-smart-table.component';
import { ProgressComponent, Tabs2Module, TextColorDirective } from '@coreui/angular';
import { UpdateSettingComponent } from '../update-setting/update-setting.component';
import { ReactiveFormsComponent } from '../../reactive-forms/reactive-forms.component';

enum tabs {
  profile = 'profile',
  settings = 'settings',
  invoicesettings = 'invoicesettings',
  invoiceList = 'invoice_list',
  notifications = 'notifications',
  kyb = 'kyb'
}

@Component({
  selector: 'rm-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
  standalone: true,
  imports: [
    NgClass, TranslateModule, NgIf, Ng2SmartTableComponent, ProgressComponent, TextColorDirective, 
    UpdateSettingComponent, ReactiveFormsComponent, NgFor, Tabs2Module
  ]
})
export class EditCompanyComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public currentTab: tabs = tabs.settings;
  public allTabls = tabs;
  public company: Treezor.User.Definition;
  public companyOrders: RoadMateOrders[] = [];
  public products: RoadMateProduct[] = [];
  public settingDefinition: FormDefinition;
  public settings: Setting[] = [];
  public settingViewing = true;
  public currentSetting: Setting | null;
  public invoices: RMInvoice[] = [];
  public hasFetchedInvoices = false;
  public kybDef: any;
  public kyb: Partial<Kyb>;
  public lineItems: LineItems[];
  public productsddl: DropDownListOption[] = Object.values(roadmateProducts).map((el: string) => {return {label: el, value: el}});
  public autmatic_invoicingSetup = false;
  public defaultSelectedOption: DropDownListOption = {label: roadmateProducts.FMD, value: roadmateProducts.FMD};
  public tableSettings: any;
  public tableinvoiceSettings: any;
  public companyRef: string;
  public agentRef: string;
  public notificationDef: any;
  public initNotification: SendableNotification;
  public loadingKyb = false; 
  public invoiceSettingNames: SettingNames[] = [];
  public invoiceSettingValues: Setting[] = [];
  loading = -1;
  public intent = '';
  // public loading = false;
  override set data(value: any) {
    if (!value || !value.company) {
      return;
    } else {
      if (value.agentRef) {
        this.agentRef = value.agentRef;
      }
      if (value.companyRef) {
        this.companyRef = value.companyRef;
      }
      this.company = value.company;
    }
  }
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private translate: TranslateService,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    // this.companyRef = this.fs.currentCompany.ref;
    // this.agentRef = this.fs.currentAgent.ref;
    if (!this.agentRef || !this.companyRef) {
      throw error_messages.PLEASE_SELECT_ONE_COMPANY;
    }
    this.invoiceSettingNames = [
      SettingNames.COMPANY_PAYMENT_CM_PLAN,
      SettingNames.COMPANY_PAYMENT_FMD_PLAN,
      SettingNames.COMPANY_PAYMENT_DP_PLAN,
      SettingNames.COMPANY_PAYMENT_CM_PLAN_PRICE,
      SettingNames.COMPANY_PAYMENT_FMD_PLAN_PRICE,
      SettingNames.COMPANY_PAYMENT_DP_PLAN_PRICE,
      SettingNames.COMPANY_PAYMENT_FMS_TYPE,
      SettingNames.COMPANY_APPLY_FMS,
      SettingNames.COMPANY_INVOICE_NEW_CARDS,
      SettingNames.COMPANY_INVOICE_AGENT,
      SettingNames.COMPANY_PAYMENT_FMS,
      SettingNames.COMPANY_PAYMENT_CARD_PRICE,
      SettingNames.COMPANY_NUMBER_OFFERED_CARDS,
      SettingNames.COMPANY_PAYMENT_SEND_TO,
      SettingNames.COMPANY_PAYMENT_CONTRACT_PERIOD,
      SettingNames.COMPANY_AUTOMATIC_INVOICING,
      SettingNames.COMPANY_OPTIONAL_INVOICING_REFERENCE,
      SettingNames.APPLY_FMS_TO_USERS,
      SettingNames.USER_FMS_PRICE,
      SettingNames.USE_YEARLY_SUBSCRIPTION_FEE,
      SettingNames.YEARLY_FMD_SUBSCRIPTION_FEE_PRICE,
      SettingNames.YEARLY_CM_SUBSCRIPTION_FEE_PRICE,
      SettingNames.YEARLY_DP_SUBSCRIPTION_FEE_PRICE
    ];
    this.initNotificationObject();
    this.getDefinitions();
    this.getCompanyOrders();
    this.getProducts();
    this.getKyb();
    
  }

  private async getKyb() {
    try {
      this.kybDef = this.fs.objectsList[ObjectDefList.kyb];
      this.kyb = await this.fs.getKyb(this.agentRef, this.companyRef);
    } catch (e) {
      console.error('getKyb', e);
    }
  }

  public async createKyb() {
    this.loadingKyb = true;
    try {
      
      const kyb: Partial<Kyb> = {
        createdAt: moment().toISOString(),
        createdBy: 'webhook',
        updatedAt: moment().toISOString(),
        updatedBy: 'webhook',
        submittedAt: '',
        companyName: this.company.legalName,
        status: kybStatus.kyb_not_submitted,
        comments: [''],
        level: this.company.kycLevel,
        source: 'webhook',
        agentRef: this.company.agentRef,
        ref: this.company.ref,
        entityType: this.company.entityType,
        legalSector: this.company.legalSector,
        legalAnnualTurnOver: this.company.legalAnnualTurnOver,
        legalNetIncomeRange: this.company.legalNetIncomeRange,
        userId: `${this.company.userId}`,
      };
      if (!this.company.ref) {
        throw 'No company ref';
      }
      await this.fs.createKyb(kyb, this.company.ref);
      this.kyb = kyb;
    } catch (e) {
      console.error('', e);
    } finally {
      this.loadingKyb = false;
    }
  }

  private initNotificationObject() {
    this.initNotification = {
      title: '',
      body: '',
      agentRef: this.agentRef,
      companyRef: this.companyRef,
      createdAt: (new Date()).toISOString(),
      scope: NotificationScope.companyEmployees
    }
  }

  private async getRoadMateInvoices() {
    this.loading = 3;
    try {
      this.invoices = await this.fs.getRoadMateInvoices(this.agentRef, this.companyRef);
    } catch (e) {
      console.error('getRoadMateInvoices', e);
    } finally {
      this.loading = -1;
    }
  }

  public updateSelectedProduct(item: DropDownListOption, index: number) {
    this.lineItems = this.lineItems.map(
      (el, i) => {
        if (i === index) {
          el.product = item.value as roadmateProducts;
        }
        return el;
      }
    );
  }
  public updateAmount(index: number) {
    setTimeout(() => {
      this.lineItems = this.lineItems.map(
        (el, i) => {
          if (i === index) {
            el.amount = el.qte * el.pricePerUnit;
          }
          return el;
        }
      )
    }, 250);
  }

  public async createFirstInvoice() {
    this.loading = 4;
    try {
      // const response = await this.ff.createQBInvoice(
      //   this.agentRef,
      //   this.companyRef,
      //   this.lineItems
      // );
      // if (!response.error) {
      //   this.toast.showSuccessToast('La facture a bien été créée');
      // } else {
      //   this.toast.showGenericError('Une erreur est survenue lors de la creation de la facture');
      // }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError('Une erreur est survenue lors de la creation de la facture');
    } finally {
      this.loading = -1;
    }
  }

  public doneEditing(response: boolean) {
    this.currentSetting = null;
    this.settingViewing = true;
  }

  private async getProducts() {
    try {
      this.products = await this.fs.getRoadMateProducts();
      if (this.products.length > 0) {
        const wallets = await this.fs.getWallets(this.agentRef, this.companyRef);
        this.products.forEach(el => {
          const wallet = wallets.find(w => w.eventName === el.code);
          if (wallet?.ribUrl) {
            el.url = wallet.ribUrl;
          }
        })
      }
    } catch (e) {
      console.error('getProducts', e);
    }
  }

  public openIban(url: string) {
    window.open(url, '_blank');
  }

  public async getDefinitions() {
    try {
      this.settingDefinition = this.fs.objectsList[ObjectDefList.settings];
      this.notificationDef = this.fs.objectsList[ObjectDefList.notification];
      const subject = new Subject<Setting[]>()
      this.subs.push(
        subject.subscribe(
          values => {
            if (values.length) {
              this.settings = values;
              const automaticSetting = this.settings.find(el => el.name === SettingNames.COMPANY_AUTOMATIC_INVOICING);
              if (automaticSetting) {
                this.autmatic_invoicingSetup = !!automaticSetting.value;
              }
              this.invoiceSettingValues = this.settings.filter(el => this.invoiceSettingNames.indexOf(el.name) > -1);
            }
          }
        )
      );
      this.unsubscribe = this.fs.getCompanyDefaultSettings$(this.agentRef, this.companyRef, subject);
      this.tableSettings = await getTableSettings(this.settingDefinition, this.translate);
      const invoiceDef = this.fs.getObjectDefinitionByRef(ObjectDefList.rmInvoice);
      this.tableinvoiceSettings = await getTableSettings(invoiceDef, this.translate);
    } catch (e) {
      console.error('getDefinitions', e);
    }
  }

  public selecteSetting(item: {data: Setting}) {
    this.currentSetting = item.data;
    this.settingViewing = false;
  }

  private async getCompanyOrders(reloadProducts = false) {
    try {
      this.companyOrders = await this.fs.getClientOrders(this.agentRef, this.companyRef);
      if (reloadProducts) {
        await this.getProducts();
      }
    } catch (e) {
      console.error('getCompanyOrders', e);
    }
  }

  public hasOrderThisProduct(product: RoadMateProduct) {
    if (!this.companyOrders || this.companyOrders.length === 0) {
      return false;
    }
    if (this.companyOrders.find(el => el.code === product.code)) {
      return true;
    } else {
      return false;
    }
  }

  public getOrderByCode(productCode: string): string {
    const order = this.companyOrders.find(el => el.code === productCode);
    return order?.orderDate ?? '';
  }

  public async addProduct(product: RoadMateProduct) {
    if (this.intent !== product.code) {
      this.intent = product.code as string;
      setTimeout(() => {
        this.intent = '';
      }, 5000);
      return;
    }
    this.intent = '';
    const index = this.products.indexOf(product);
    const newOrder: RoadMateOrders = {
      orderDate: (new Date()).toISOString(),
      orderedBy: this.fs.currentAppUser.email,
      updateDate: (new Date()).toISOString(),
      product: {...product},
      walletID: '',
      code: product.code,
      beneficiaryGroup: [],
      recurrentPaymentFrequency: paymentFrequency.monthly,
      usualPaymentDay: usualPaymentDay.fifteenOfTheMonth,
      optinAlertLowBalance: true,
      optinAlertNoTransfet: true,
      autoTransferToPaymentAccount: true,
      usersCount: 0
    };
    const cleanedObject: RoadMateOrders = JSON.parse(JSON.stringify(newOrder));
    this.loading = index;
    try {
      await this.fs.addNewOrders([cleanedObject], this.agentRef, this.companyRef);
      await rmSleep(10000);
      await this.getCompanyOrders(true);
      this.toast.showSuccessToast(`Le produit ${product.name} a été ajouté au compte de ${this.company.legalName}. Les admins de cette entreprise recevront un email`);
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError(`Le produit n'a pu être ajouté.`);
    } finally {
      this.loading = -1;
    }
  }

  public setTab(tab: tabs) {
    this.currentTab = tab;
    if ((tab === tabs.invoicesettings || tab === tabs.invoiceList) && !this.hasFetchedInvoices) {
      this.getRoadMateInvoices();
      this.hasFetchedInvoices = true;
    }
  }

  public async sendNotification(notification: SendableNotification) {
    if (!notification.agentRef || !notification.companyRef) {
      this.toast.showGenericError('Veuillez selectionner une entreprise et un agent');
      return;
    }
    this.loading = 1;
    try {
      const response = await this.ff.saSendPushNotification(
        notification.title,
        notification.body,
        notification.scope,
        notification.agentRef,
        notification.companyRef,
      );
      if (response.message === error_messages.OK) {
        const companyName = this.company.legalName;
        switch (notification.scope) {
          case NotificationScope.agentEmployees:
            const agent = this.fs.currentAgent.name;
            this.toast.showSuccessToast('Notification envoyée aux employés de ' + agent);
            break;
          case NotificationScope.companyEmployees:
            this.toast.showSuccessToast('Notification envoyée aux employés de ' + companyName);
            break;
          case NotificationScope.admins:
            this.toast.showSuccessToast('Notification envoyée aux administrateurs de ' + companyName);
            break;
        }
        this.initNotificationObject();
      } else {
        this.toast.showGenericError('Une erreur est survenue lors de l\'envoi de la notification');
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError('Une erreur est survenue lors de l\'envoi de la notification');
    } finally {
      this.loading = -1;
    }
  }
}
