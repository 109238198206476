<h4>
  {{'confirm_payout_description' | translate}}
</h4>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'payout_from' | translate}}</label>
      <div class="form-control" disabled="">
        {{(transfer.walletName ?? '')| translate}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'transfer_to' | translate}}</label>
      <div class="form-control" disabled="">
        {{(transfer.baneficiaryName ?? '') | translate}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'payout_amount' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.amount| rm_currency}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'payout_reference' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.label}}
      </div>
    </div>
  </div>
</div>


