<h5>
  {{'refunds_and_transactions'|translate}}
</h5>
<div class="row" *ngIf="mobilityAccounts && fmdUser">
  <div class="col-6">
    <div class="row">
      <div class="col-12">
        <strong>
          {{'split_in_tracation_types'|translate}} :
        </strong><br>
        <span class="amount">
          <strong>
            {{'FMD'|translate}} :
          </strong>
            {{mobilityAccounts.FMD| rm_currency: '1.2-2'}} <br>
        </span>
        <span class="amount">
          <strong>
            {{'PT'|translate}} :
          </strong>
          {{mobilityAccounts.PT| rm_currency: '1.2-2'}}<br>
        </span>
        <span class="amount">
          <strong>
            {{'RTT'|translate}} :
          </strong>
          {{mobilityAccounts.RTT| rm_currency: '1.2-2'}}<br>
        </span>
      </div>
    </div>
  </div>
  <div class="col-6">
    <!-- TODO -->
    <!-- <canvas *ngIf="chartReady"
      baseChart 
      [data]="pieChartData" 
      [labels]="pieChartLabels" 
      [chartType]="pieChartType"
      [options]="pieChartOptions" 
      [plugins]="pieChartPlugins" 
      [legend]="pieChartLegend"
      [colors]="colors"
      height="90"
    >
    </canvas> -->
  </div>
</div>
<c-progress variant="striped" *ngIf="fetchingUserTransactions" [value]="100" animated class="mb-3" />
<div class="row detached" *ngIf="transactions?.length === 0">
  <div class="col-12">
    <p>
      {{'no_transactions_made_yet'|translate}}
    </p>
  </div>
  <div class="col-4">
    <img src="/assets/svg/empty.svg">
  </div>
</div>
<div class="row detached" *ngIf="transactions?.length">
  <div class="col-12">
    <div class="card mb-3">
      <div class="card-header rm-row dispatch-away">
        <h5 class="bold rm-clickable" #transactionPanel="var" [var]="true" (click)="transactionPanel.var = !transactionPanel.var">
          {{'all_transactions_made_by_user' | translate}}
          <i class="cil-chevron-circle-down-alt" *ngIf="!transactionPanel.var"></i>
          <i class="cil-chevron-circle-up-alt" *ngIf="transactionPanel.var"></i>
        </h5>
      </div>
      <div class="card-body" *ngIf="transactionPanel.var">
        <ng2-smart-table
          *ngIf="transactions?.length && transactionsSettings"
          [source]="transactions"
          [settings]="transactionsSettings"
          [fileHeaders]="headersTranslated"
          [showExporter]="true"
        ></ng2-smart-table>
      </div>
    </div>
  </div>
</div>