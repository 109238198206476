<c-progress variant="striped" [value]="100" animated class="mb-3" color="primary" *ngIf="!treezorUploadedDocuments">
  {{'loading' | translate}}
</c-progress>
@if (treezorUploadedDocuments && treezorUploadedDocuments.length) {
  <c-row *ngIf="treezorUploadedDocuments?.length">
    <c-col sm="4" md="2" *ngFor="let doc of treezorUploadedDocuments">
      <c-card>
        <img cCardImg="top" src="./assets/svg/pdf.svg">
        <c-card-body>
          <p cCardText>
            <a href="{{doc.url}}" target="_blank">
              Doc {{doc.documentId}} - User {{doc.userId}}
            </a>
          </p>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
} @else {
  <h3 *ngIf="treezorUploadedDocuments?.length === 0">
    Aucun document n'a été téléchargé pour le moment
  </h3>
}