<c-row class="push-down">
  <c-col sm="6" lg="3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Profil
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Nom :</strong> {{request.lastname}} {{request.firstname}}</li>
          <li class="list-group-item"><strong>Email :</strong> 
            {{request.email}}
            <svg [cIcon]="icons.cilCopy" size="sm" title="copy" (click)="copy(request.email)"></svg>
          </li>
          <li class="list-group-item"><strong>Budget :</strong> <span class="badge text-bg-primary">{{request.product | translate}}</span></li>
          <li class="list-group-item" *ngIf="isRTT"><strong>Prise en charge réelle :</strong> {{percentage}}%</li>
          <li class="list-group-item" [ngClass]="{'red': companyRefunRate !== percentage}" *ngIf="isRTT"><strong>Prise en charge souhaitée :</strong> {{companyRefunRate}}%</li>
          <li class="list-group-item"><strong>Type :</strong> {{request.createdAt | rm_date}}</li>
          <!-- <li class="list-group-item"></li>
          <li class="list-group-item"></li> -->
        </ul>
      </div>
    </div>
  </c-col>
  <c-col sm="6" lg="3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Demande
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Type :</strong> <span class="badge text-bg-primary">
            {{request.type | translate}}
            </span>
          </li>
          <li class="list-group-item" *ngIf="!isIKV"><strong>Marchant déclaré : </strong> {{request.merchantName}}</li>
          <li class="list-group-item" *ngIf="!isIKV"><strong>Montant payé : </strong> {{request.invoices.amount | rm_currency}}</li>
          <li class="list-group-item" *ngIf="isIKV"><strong>Km parcourrus : </strong> {{request.distance}} Km</li>
          <li class="list-group-item" *ngIf="isIKV"><strong>Dates : </strong> {{request.from | rm_display_month_day}} - {{request.to | rm_display_month_day}}</li>
          <li class="list-group-item"><strong>Montant à rembourser : </strong> {{request.amount | rm_currency}}</li>
          <li class="list-group-item" *ngIf="isMonthly"><strong>Mois : </strong>  {{request.month | rm_month_zero_index: request.year}}</li>
          <li class="list-group-item"><strong>Justificatif : </strong> 
            <div class="row">
              <div class="col-12">
                <div class="row" *ngIf="request.invoices?.files?.length">
                  <div 
                    *ngFor="let item of request.invoices.files"
                    class="col-auto invoice-icon rm-clickable"
                    (click)="openFile(item.url)"
                  >
                    <img [src]="item.name | rm_img_file_extension" [title]="item.name" [alt]="item.name">
                    <div class="smaller-text">
                      {{item.name|truncate:15:false:true}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </c-col>
  <c-col sm="6" lg="3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Traitement
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Merchant : </strong> {{request.merchantRef ?? '' | display_ddl_value: merchantsList}}</li>
          <li class="list-group-item"><strong>Snippet : </strong> {{request.addHtmlSnippet ?? '' | display_ddl_value: htmlSnippetsList}}</li>
          <li class="list-group-item"><strong>Plateforme : </strong> 
            <span class="badge text-bg-primary" *ngIf="request.createdByDevice">
              {{request.createdByDevice | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="request.createdByVersion">
              {{request.createdByVersion | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="request.version && request.version !== request.createdByVersion">
              {{request.version | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="request.deviceType && request.deviceType !== request.createdByDevice">
              {{request.deviceType | translate}}
            </span> 
          </li>
        </ul>
      </div>
    </div>
  </c-col>
  <c-col sm="6" lg="3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Document AI
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngIf="!request.documentaiResponses">
            Document non traité
          </li>
          <ng-container *ngIf="request.documentaiResponses">
            <ng-container *ngFor="let item of request.documentaiResponses">
              <li class="list-group-item">
                <strong>Nom : </strong> {{item.traveler_name}}
              </li>
              <li class="list-group-item">
                <strong>Document : </strong> {{item.supplier_name}}
              </li>
              <li class="list-group-item">
                <strong>Date émission : </strong> {{item.receipt_date}}
              </li>
              <li class="list-group-item">
                <strong>Date de début : </strong> {{item.start_date}}
              </li>
              <li class="list-group-item">
                <strong>Date de fin : </strong> {{item.end_date}}
              </li>
              <li class="list-group-item">
                <strong>Montant : </strong> {{item.total_amount}}
              </li>
              <li class="list-group-item" *ngIf="request.similarInvoiceDetected">
                <strong class="red">Un justificatif similaire a déjà été </strong>
              </li>
              <li class="list-group-item" *ngIf="request.similarInvoiceUrl">
                <a href="{{request.similarInvoiceUrl}}" target="_blank">Visualiser le justificatif en question</a>
              </li>
              <li class="list-group-item" *ngIf="request.similarRequestRef">
                <strong>Ref de la demande : </strong> {{request.similarRequestRef}}
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </c-col>
</c-row>
<c-row>
  <c-col *ngIf="missingSettingForCompany" sm="12">
    <p class="red bold">
      ⚠️ Les paramètres de cette entreprise (taux de remboursement et nb de mois à rembourser) n'a pas pu être pris en compte.
    </p>
  </c-col>
  <c-col  *ngIf="inconsistentAnnualRefundAmountMessage" sm="12">
    <p class="red bold" [innerHTML]="inconsistentAnnualRefundAmountMessage"></p>
  </c-col>
  <c-col *ngIf="inconsistentAnnualRefundAmountMessage" sm="6" lg="2">
    <button class="btn btn-primary" type="button" (click)="checkIfNavigAnnuel(true)" [disabled]="loading">
      {{'apply_change' | translate}}
    </button>
  </c-col>
  <c-col sm="6" lg="8"></c-col>

  <c-col sm="12" md="6">
    <h5>
      Fusionner cette demande avec une autre :
    </h5>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="destinationRefundRef"
        placeholder="Ref de la demande à fusionner"
      >
    </div>
    <p>
      * La ref de la demande saisie ci-dessus prendra les valeur de la demande actuelle qui sera supprimée définitivement.
    </p>
    <div class="form-group">
      <button
        [disabled]="loading || !destinationRefundRef"
        class="btn btn-block btn-primary"
        (click)="mergeRequest()"
        type="button"
      >
        <span *ngIf="!loading">
          {{'merge' | translate}}
        </span>
        <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      </button>
    </div>
  </c-col>
  <c-col sm="12" md="6">
    <h5>
      Notifications et messages envoyés à cet utilisateur
    </h5>
    <div class="form-group">
      <c-input-group class="mb-3">
        <span cInputGroupText id="amount-per-user">
          <svg [cIcon]="icons.cilEnvelopeClosed" size="sm" title="Euros"></svg>
        </span>
        <input 
          cFormControl
          class="form-control no-verify"
          type="text" [(ngModel)]="newMessageSubject"
          placeholder="{{'search_user' | translate}}"
        />
      </c-input-group>
    </div>
    <div class="form-group">
      <textarea
        [(ngModel)]="newMessage"
        class="form-control"
        rows="3"
      ></textarea>
    </div>
    <div class="row">
      <div class="col-6">
        <c-button-group aria-label="Button group with nested dropdown" role="group">
          <button cButton color="primary" [disabled]="loading || askingAi || !newMessage || !newMessageSubject" (click)="sendMessage()">
            <span *ngIf="!loading || !askingAi">
              {{'send' | translate}}
            </span>
            <span *ngIf="loading || askingAi" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
          </button>
          <!-- <button cButton color="primary">2</button> -->
          <c-dropdown variant="btn-group">
            <button cButton cDropdownToggle>
              Pimp ton texte !
            </button>
            <div cDropdownMenu>
              <div cDropdownItem  (click)="askAi(llms.gemini)" class="rm-row dispatch-away rm-clickable">
                <label>
                  Improve with Gemini
                </label>
                <img *ngIf="!askingAi" src="/assets/svg/gemini.svg" alt="Gemeni" style="width: 25px; height: 25px;">
                <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
              </div>
              <div cDropdownItem  (click)="askAi(llms.openai)" class="rm-row dispatch-away rm-clickable">
                <label>
                  Improve with OpenAI
                </label>
                <img *ngIf="!askingAi" src="/assets/svg/chatgpt.svg" alt="OpenAI" style="width: 25px; height: 25px;">
                <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
              </div>
              <div cDropdownItem  (click)="askAi(llms.mistral)" class="rm-row dispatch-away rm-clickable">
                <label>
                Improve with Mistral
                </label>
                <img *ngIf="!askingAi" src="/assets/svg/mistral.svg" alt="Claude" style="width: 25px; height: 25px;">
                <c-spinner *ngIf="askingAi" aria-hidden="true" size="sm" color="info" variant="grow"></c-spinner>
              </div>
            </div>
          </c-dropdown>
        </c-button-group>
      </div>
      <div class="col-6">
        <rm-single-select
          [array]="messageCanals"
          [resetSelectedITem]="'notification'"
          (changeEmitter)="changeCanal($event)"
          [fullWidth]="true"
        ></rm-single-select>
      </div>
    </div>
    <ng-container *ngIf="notifications?.length; else emptynotifications">
      <p>
        Liste des notes écrites :
      </p>
      <div class="card note" *ngFor="let item of notifications">
        <div class="card-header">
          {{$any(item).createdAt ?? '' | rm_iso_to_french_datetime}} | {{$any(item).createdBy ?? ''}} | <strong>{{$any(item).finalCanal}}</strong>
        </div>
        <div class="card-body">
          <strong>{{item.notification.title}} : </strong>
          <p [innerHTML]="item.notification.body"></p>
        </div>
      </div>
    </ng-container>
    <ng-template #emptynotifications>
      <p>
        Aucune notification ou message envoyé à cet utilisateur
      </p>
      <img src="/assets/svg/empty.svg">
    </ng-template>
  </c-col>
  <c-col sm="12" md="6">
    <h5>
      Notes à prendre en compte à propos de cet utilisateur
    </h5>
    <div class="form-group">
      <textarea
        [(ngModel)]="newNote"
        class="form-control"
        rows="3"
      ></textarea>
    </div>
    <div class="form-group">
      <button
        [disabled]="loading || !newNote"
        class="btn btn-block btn-primary"
        (click)="addNote()"
        type="button"
      >
        <span *ngIf="!loading">
          {{'save' | translate}}
        </span>
        <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      </button>
    </div>
    <ng-container *ngIf="notes?.length; else emptynotes">
      <p>
        Liste des notes écrites :
      </p>
      <div class="card note" *ngFor="let item of notes">
        <div class="card-header">
          {{item.createdAt ?? '' | rm_iso_to_french_datetime}} | {{item.createdBy}}
        </div>
        <div class="card-body" [innerHTML]="item.content"></div>
      </div>
    </ng-container>
    <ng-template #emptynotes>
      <p>
        Aucune note pour cet utilisateur
      </p>
      <img src="/assets/svg/empty.svg">
    </ng-template>
  </c-col>
  
</c-row>